//base-style
html {
  font-size: 67.5%;
  background: black;
}
body {
  background: black;
  font-family: $baseFontFamily;
  height: auto !important;
  @include fs(12);
  line-height: $baseLineHeight;
  min-width: 320px;
  color: $baseFontColor;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  @include sm() {
    min-width: $baseMinWidth;
  };
  word-wrap: break-word;
  word-break: break-all;
}

//load
#loadBegin{height: 100%; width: 100%; position: fixed; top:0; left:0; z-index: 6000; background:#000000;}
#loadBegin .handle{-moz-perspective:1000; -webkit-perspective:1000; -o-perspective:1000; -ms-perspective:1000; height: 100%; width: 100%; background:#000000; position: fixed; z-index: 10000; -webkit-transition:all 0.6s; transition:all 0.6s; }
#loadBegin #load_in{opacity: 1; position: fixed; top: 50%; left: 50%; margin:-29px 0 0 -50px; }
#loadBegin .loaded #load_in{opacity: 0; }
#loadBegin .scale{opacity: 0; }
#loadBegin {display: none\9;}
.siteid {
  color: #666;
  position: absolute;
  bottom: 0;
  font-size: 12px;
  width: 100vw;
  padding: 20px 4vw;
  text-align: center;
  @include xs {
    text-align: left;
    font-size: 10px;
  };
}

a {
  color: $baseFontColor;
  text-decoration: $linkDecoration;
  transition: 0.15s all linear;
  &:hover {
    color: $hoverColor;
    text-decoration: $hoverDecoration;
    transition: 0.15s all linear;
  }
}

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  th,td {
    border: $baseBorder;
    padding: $tablePadding;
  }
}

img {
  vertical-align: top;
  max-width: 100%;
  height: auto;
}

.sp_item {
  display: none;
}


ul,ol {
  padding-left: 0;
  list-style: none;
  margin:0;
}

b {font-weight: bold;}

.inner {@include defaultWidth;}
.center {text-align: center;}
.left {float: left;}
.right {float: right;}
.label {
  background: #eee;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 4px 8px;
}

input:focus, textarea:focus, keygen:focus, select:focus {
  outline-offset: -2px;
}

:focus {
  outline: none;
}
a:focus {
  outline: none;
  text-decoration: none;
  color: $accentFontColor;
}

::selection {
  background:$accent;
}
::-moz-selection {
  background:$accent;
}


//-----------------------------------------------------------------------------------------

.op0 {@include opacity(0);}

.disN {display: none;}

.textIn {text-indent: -9999px;}
//-----------------------------------------------------------------------------------------

.l-box {@include clearfix;}

.l-boxl {float: left;}

.l-boxr {float: right;}

.l-list {
  @include clearfix;
  > li {float: left;}
}

@include sm {
  a img:hover {@include hoverAction;}
}


p {margin: 0 0 $baseMargin;}

.fb {font-weight: bold;}

//-----------------------------------------------------------------------------------------

@include sm {
  .container {width: $baseMinWidth+30px;}
}

//-----------------------------------------------------------------------------------------


//margin
@for $i from -20 through 30 {
  .mt#{5*$i} {
    margin-top: #{5*$i}px!important;
  }
}

@for $i from 0 through 30 {
  .ml#{5*$i} {
    margin-left: #{5*$i}px!important;
  }
}
@for $i from 0 through 30 {
  .mr#{5*$i} {
    margin-right: #{5*$i}px!important;
  }
}
@for $i from 0 through 30 {
  .mb#{5*$i} {
    margin-bottom: #{5*$i}px!important;
  }
}
/* mice panel */
#mice {
  position: absolute;
  bottom: 100px;
  @include xs {
    bottom: 80px;
  };
  left: 50%;
  margin-left: -11px;
  z-index: 15;
}

#mice span {
  display: block;
  width: 9px;
  height: 9px;
  transform: rotate(45deg);
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  margin: 5px 0 3px 6px;
  border-radius: 2px;
  &.n {
    margin-top: -5px;
  }
}

.mouse {
  height: 35px;
  width: 22px;
  border-radius: 10px;
  transform: none;
  border: 2px solid white;
  top: 170px;
}

.wheel {
  height: 6px;
  width: 6px;
  display: block;
  border-radius: 50%;
  margin: 7px auto;
  background: white;
  position: relative;
  animation: mouse-wheel 1.5s ease infinite;
}

@keyframes mouse-wheel {
   0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(12px);
  }
}

.section_inner {
  vertical-align: middle;
}
.login {
  input[type=text] {
    background: #555;
    color: $accentFontColor;
  }
  form {
    background: #222;
    .input, input[type=checkbox] {
        background: #555;
        color: $accentFontColor;
    }
  }
}
