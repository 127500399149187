/*--------------------------------------------
  _spotlight.scss
---------------------------------------------*/

@charset "UTF-8";
#spotlight {
  width: 100%;
  min-width: 1180px;
  margin: 0 auto;

  @include xs{
    img{
      width: 100%;
    }
  }

  @include xs{
    min-width: initial;
    word-break: break-word;
  }

  // ===========================================
  // utility
  // ===========================================
  .spot-wrap--topbottom {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .spot-wrap--side {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
  }
  @include xs{
    .spot-wrap--topbottom {
      padding-top: 5%;
      padding-bottom: 5%;
    }
    .spot-wrap--side {
      padding-left: 3%;
      padding-right: 3%;
    }
  }

  .bg-gray{
    background-color: $sub;
  }

  .bg-white{
    background-color: #fff;
  }

  .section-title-wrap{
    display: table;
    width: 100%;
  }
  *{
    line-height: 2;
  }

  .section-title{
    background-image: url("../img/spotlight/section_title_bg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    text-align: center;
    display: table-cell;
    height: 100px;
    vertical-align: bottom;
  }

  .section-title--text{
    vertical-align: middle;
    letter-spacing: 0.2em;
    font-weight: bold;
  }
  .section-title__img{
    margin: 0 auto;
  }

  .nobg{
    background-image: none;
    height: auto;
  }

  .spotlight-btn{
    background-color: $spotlightMainColor;
    padding: 0.5em 2em;
    display: inline-block;
    width: 360px;
    text-align: center;
    letter-spacing: 0.2em;
    font-size: 21px;
    box-shadow: 0 5px 10px rgba(0,0,0,0.5);
    color: #fff;
    &:hover{
      color: #fff;
      box-shadow: 0 5px 13px rgba(0,0,0,0.7);
    }
  }

  .spotlight-btn__wrapper{
    text-align: center;
    margin-top: 60px;
  }

  @include xs{
    .spotlight-btn{
      width: 90%;
      font-size: 15px;
    }
    .spotlight-btn__wrapper{
      margin-top: 5%;
    }
  }

// ===========================================
// keyv
// ===========================================
  .key_visual {
    background: url("../img/spotlight/key-visual.jpg");
    background-repeat: no-repeat;
    width: 100%;
    height: 0;
    position: relative;
    // 縦/横*100
    padding-bottom: 56.1864%;
    background-size: contain;


    .headding-block{
      position: absolute;
      top: 190px;
      left: 110px;
      .headding-text{
        display: block;
        text-shadow: 0 5px 7px #000;
        font-family: $subFontFamily;
        text-align: center;
        line-height: 1;
        &--top{
          font-size: 60px;
        }
        &--middle{
          font-size: 90px;
        }
        &--bottom{
          font-size: 80px;
        }
      }
    }

    .sns-icons {
      width: 315px;
      position: absolute;
      left: 145px;
      top: 450px;
      margin-left: 245px;
      a {
        font-size: 12px;
        padding: 2px 10px;
      }
    }
  }

  @include xs{
    .key_visual {
      position: static;
      padding: 40px 0 10px;
      background: #fff;
      height: auto;
  
  
      .headding-block{
        position: static;
        background-image: url("../img/spotlight/section_title_bg.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        padding: 10% 0;
        .headding-text{
          text-shadow: 0 5px 4px #015e59;
          color: #000;
          line-height: 1;
          &--top{
            font-size: 30px;
          }
          &--middle{
            font-size: 45px;
          }
          &--bottom{
            font-size: 45px;
          }
        }
      }
  
      .sns-icons {
        position: static;
        margin: 10px auto;
        width: 90%;
        text-align: center;
        a {
          font-size: 14px;
          display: inline-block;
          width: 40px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          padding: 0;
          border-radius: 50%;
          float: none;
        }
      }
    }
  }
// ===========================================
// 今回のアーティスト
// ===========================================
  .about-artist{
    &-name{
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 50px;
      z-index: 1;
      &__number{
        width: auto;
      }
      &__text{
        color: $spotlightMainColor;
        font-size: 25px;
        padding-left: 10px;
        letter-spacing: 0.2em;
      }
      &:after{
        position: absolute;
        content: "";
        border-bottom: 1px dotted #fff;
        width: 100%;
        left: 0;
        bottom: 10px;
        z-index: -1;
        display: block;
      }
    }
    .artist-profile{
      display: flex;
      padding-top: 30px;
      padding-bottom: 50px;
      max-width: 980px;
      &__text-wrap{
        width: 50%;
        padding-right: 2%;
      }
      &__text{
        font-size: 14px;
        //テキスト量に応じて変更
        margin-bottom: 15px;
      }
      &__text-lead{
        font-size: 17px;
        color: $spotlightMainColor;
      }
      &__sns-wrap{
        display: flex;
        justify-content: flex-end;
      }
      &__sns-item{
        background-color: $spotlightMainColor;;
        width: 2em;
        height: 2em;
        border-radius: 50%;
        font-size: 2em;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);;
        transition: 0.5s;
        i{
          color: #fff;
        }
        &:hover{
          transform: scale(1.2);
          box-shadow: 0 0px 10px 6px #636363;
        }
        //twitterアイコン
        &--twitter{
          background-color: #6cadde;
        }
      }
      &__img{
        width: 50%;
        img{
          width: 100%;
        }
      }
    }
    .artist-infomation{
      border: 1px solid $spotlightMainColor;
      padding: 2em 5em;
      font-size: 15px;
      &__top{
        display: flex;
        justify-content: space-between;
        .artist-infomation__contents{
          width: 47%;
        }
      }
      &__bottom{}
      &__contents{
        &-title{
          text-align: center;
          background-color: $spotlightMainColor;
          font-size: 22px;
          padding: 0.4em 0;
          color: $sub;
        }
        &-text{
          padding-bottom: 30px;
        }
      }
      &-place{}
      .free-live{
        span{
          display: block;
        }
        .attention{
          font-size: 15px;
        }
        .artist-infomation-place{
          padding-left: 10px;
          font-size: 0.9em;
          display: inline-block;
        }
      }
      .music-card{
        .attention{
          color: #ff9999;
        }
      }
      &__contents-text--list-wrap{
        display: flex;
        justify-content: space-between;
      }
      &__contents-text--list{
        width: 47%;
        &-item{
          list-style-type: disc;
          list-style-position: inside;
        }
      }
    }
  }

  @include xs{
    .about-artist{
      &-name{
        &__number{}
        &__text{
          font-size: 20px;
        }
      }
      .artist-profile{
        display: flex;
        padding-top: 3%;
        padding-bottom: 5%;
        flex-direction: column-reverse;
        &__text-wrap{
          width: 100%;
          padding-right: 0;
        }
        &__text{
          font-size: 13px;
          //テキスト量に応じて変更
          margin-top: 10px;
        }
        &__text-lead{
          font-size: 14px;
          color: $spotlightMainColor;
        }
        &__img{
          width: 100%;
        }
      }
      .artist-infomation{
        padding: 3% 2%;
        &__top{
          flex-direction: column;
          .artist-infomation__contents{
            width: 100%;
          }
        }
        &__bottom{}
        &__contents{
          &-title{}
          &-text{}
        }
        &-place{}
        .free-live{
          span{
            display: block;
            
          }
          .attention{}
          .artist-infomation-place{
            display: block;
            text-align: right;
          }
        }
        .music-card{
          .attention{
          }
        }
        &__contents-text--list-wrap{
          flex-direction: column;
        }
        &__contents-text--list{
          width: 100%;
          &-item{
            list-style-type: disc;
            list-style-position: inside;
          }
        }
      }
    }
  }



// ===========================================
// 歴代
// ===========================================
  .grandprix-artist {
    padding: 50px 0 80px;
    &__ttl {
      text-align: center;
      font-weight: bold;
      font-size: 50px;
      letter-spacing: 0.1rem;
      margin-bottom: 20px;
    }
    &__list {
      display: flex;
      margin-top: 50px;
    }
    &__list-item {
      width: 31%;
    }
    &__list-item:not(:nth-child(1)) {
      margin-left: 3.5%;
    }
    &__list-ttl {
      margin-bottom: 15px;
      text-align: center;
      padding: 0.5rem;
      line-height: 2;
      padding-left: 4rem;
      color: #000;
      margin-top: 0;
      background-image: linear-gradient(-45deg, lighten($spotlightMainColor,15%) 25%, $spotlightMainColor 25%, $spotlightMainColor 50%, lighten($spotlightMainColor,15%) 50%, lighten($spotlightMainColor,15%) 75%, $spotlightMainColor 75%, $spotlightMainColor);
      /* 幅8px、高さ8pxで背景画像のサイズを指定 */
      background-size: 8px 8px;
      font-size: 17px;
      font-weight: bold;
      position: relative;
      &:before {
        content: url("../img/spotlight/crown.svg");
        position: absolute;
        top: -15px;
        left: 50px;
        width: 60px;
      }
    }
    &__list-image {
      width: 100%;
    }
    &__list-artist-name {
      margin-top: 20px;
      margin-bottom: 15px;
      border-bottom: 1px dotted #fff;
      font-size: 23px;
      padding-bottom: 10px;
    }
    &__list-txt {
      font-size: 15px;
      line-height: 2;
      color: #ccc;
    }
  }

  @include xs{
    .grandprix-artist {
      padding: 5% 0;
      &__ttl {}
      &__list {
        display: flex;
        flex-direction: column;

      }
      &__list-item {
        width: 100%;
      }
      &__list-item:not(:nth-child(1)) {
        margin-left: 0;
      }
      &__list-ttl {
        &:before {
          top: -5px;
          left: 50px;
          width: 50px;
        }
      }
      &__list-image {
        width: 100%;
      }
      &__list-artist-name {
        margin-top: 20px;
        margin-bottom: 15px;
        border-bottom: 1px dotted #fff;
        font-size: 23px;
        padding-bottom: 10px;
      }
      &__list-txt {
        font-size: 15px;
        line-height: 2;
        color: #ccc;
      }
    }
  }
  
  // ===========================================
  // spotlightとは
  // ===========================================
  .about-spotlight{
    color: $sub;
    font-size: 20px;
    .section-title{
      font-weight: bold;
      .small{
        font-size: 15px;
        font-weight: bold;
        color: $sub;
      }
    }
    &__text{
      font-size: 17px;
      max-width: 1040px;
      margin: auto;
    }
    .spotlight-btn__text{
      display: block;
      color: #ff0000;
      font-size: 17px;
      padding-bottom: 10px;
      .slash{
        padding: 0 0.5em;
        font-family: $subFontFamily;
        position: relative;
        &:before{
          position: absolute;
          content: "";
          display: block;
          width: 20px;
          height: 2px;
          background-color: #999;
          bottom: 10px;
        }
      }
      .slash-left:before{
        left: -10px;
        transform: rotate(50deg);
      }
      .slash-right:before{
        right: -10px;
        transform: rotate(310deg);
      }
    }
  }

  @include xs{
    .about-spotlight{
      color: $sub;
      font-size: 20px;
      &__text{
        font-size: 13px;
        margin-top: 5%;
      }
      .section-title{
        line-height: 1;
        .small{
          color: $sub;
        }
        br{
          line-height: 1.3;
        }
      }
    }
  }

  // ===========================================
  // entry
  // ===========================================
  .entry{
    .section-title--change-color{
      color: $spotlightMainColor;
    }
    .section-block + .section-block{
      padding-top: 70px;
    }
    .headding{
      text-align: center;
      position: relative;
      border-bottom: 1px dotted #fff;
      color: $spotlightMainColor;
      font-size: 21px;
      margin-bottom: 25px;
    }
    .entry-list{
      &__item{}
      &__title{
        color: $spotlightMainColor;
        font-size: 18px;
      }
      &__lead{
        color: $spotlightMainColor;
        font-size: 20px;
        display: flex; align-items: center;
        img{
          padding-right: 0.3em;
          width: 50px;
        }
        &-inner-text{
          
        }
      }
      &__text{
        max-width: 1040px;
        margin: 0 auto;
        font-size: 15px;
      }
      &__inner-list-item:not(:nth-child(1)){
        margin-top: 20px;
      }
    }
    .judge-list{
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
      &__item{
        text-align: center;
        img{

        }
    }
    &__position,&__position br{
      line-height: 1.5;
    }
    &__name,&__name br{
      font-size: 19px;
      line-height: 1.2;
    }
    &__name{
      margin-top: 0.5em;
    }
    &__position{
      margin-top: 0.3em;
    }
    &__name-margin--1{
      margin-top: 1.5em;
    }
    }
    .entry-attention{
      border: 1px solid #fff;
      padding: 2em 5em;
      &__title{
        color: #ff9999;
        font-size: 19px;
      }
      &-list{
        &__item{
          list-style-position: inside;
          list-style-type: decimal;
          font-size: 15px;
        }
      }
    }
  }

  @include xs{
    .entry{
      .section-block + .section-block{
        padding-top: 20%;
      }
      .headding{
        font-size: 16px;
      }
      .entry-list{
        &__item{}
        &__item:not(:nth-child(1)){
          margin-top: 15%;
        }
        &__title{
          font-size: 16px;
        }
        &__lead{
          img{
            padding-right: 0.3em;
            width: 40px;
          }
          &-inner-text{
            font-size: 16px;
          }
        }
        &__text{
          max-width: 1040px;
          margin: 0 auto;
          font-size: 13px;
        }
        &__inner-list-item:not(:nth-child(1)){
          margin-top: 0;
        }
      }
      .judge-list{
        flex-wrap: wrap;
        &__item{
          width: 45%;
        }
        &__item:not(:nth-child(-n+2)){
          margin-top: 5%;
        }
        &__name,&__name br{
          font-size: 17px;
        }
      }
      .entry-attention{
        padding: 5%;
        &__title{
          font-size: 17px;
        }
        &-list{
          &__item{
            font-size: 13px;
          }
        }
      }
    }
  }

// ===========================================
// form
// ===========================================

.form_confirm_btn {
  width: 300px;
  height: 50px;
  margin-top: 1em;
  color: white;
  font-size: 20px;
  padding: 15px auto;
  background: #001c56;
  border-style: none;
}

.check_step2 {
  margin: 0px 10px;
}

.check_text {
  text-align: center !important;
  th {
    padding: 30px !important;
  }
}
.entryform_box{
  background-color: #fff;
  .section-title--text{
    color: $sub;
  }
  .section-title--change-color{
    color: $spotlightMainColor;
  }
}

.form_area {
  width: 100%;
  max-width: 1100px;
  margin: 40px auto 0;
  color: #333;
  table {
    width: 100%;
    height: auto;
    margin: 0 auto 30px;
  }
  tr {
    vertical-align: inherit;
    border-color: inherit;
    th {
      padding: 15px 30px;
      vertical-align: inherit;
      width: 30%;
      font-size: 14px;
      font-weight: bold;
      color: #010e2a;
      .form-label {
        display: inline-block;
        float: right;
        background: #ccc;
        border-radius: 4px;
        padding: 0.2em 0.5em;
        color: white;
        &.required {
          background: #f39800;
        }
      }
    }
    td {
      font-size: 14px;
    }
  }
  .input_ttl {
    display: inline-block;
    width: 100px;
  }
  textarea {
    width: 100%;
    border: 1px solid #ccc;
    padding: 0.2em 0.5em;
    border-radius: 4px;
    margin: 5px 5px 5px 0;
  }
  input {
    &[type=text],
    &[type=tel],
    &[type=email] {
      width: 100%;
      border: 1px solid #ccc;
      padding: 0.2em 0.5em;
      border-radius: 4px;
      margin: 5px 5px 5px 0;
    }
  }
  textarea.w40 {
    width: 40%;
  }
  input {
    &[type=text].w40,
    &[type=tel].w40,
    &[type=email].w40 {
      width: 40%;
    }
  }
  textarea.w45 {
    width: 45%;
  }
  input {
    &[type=text].w45,
    &[type=tel].w45,
    &[type=email].w45 {
      width: 45%;
    }
  }
  textarea.w50 {
    width: 50%;
  }
  input {
    &[type=text].w50,
    &[type=tel].w50,
    &[type=email].w50 {
      width: 50%;
    }
  }
  textarea.w55 {
    width: 55%;
  }
  input {
    &[type=text].w55,
    &[type=tel].w55,
    &[type=email].w55 {
      width: 55%;
    }
  }
  textarea.w60 {
    width: 60%;
  }
  input {
    &[type=text].w60,
    &[type=tel].w60,
    &[type=email].w60 {
      width: 60%;
    }
    &[type=submit] {
      background: #efe800;
      border: none;
      border-radius: 8px;
      font-size: 24px;
      padding: 10px;
      width: 330px;
      margin: 0 auto;
      display: block;
      color: #fff;
      &:hover {
        background: #f39800;
      }
    }
  }
  .check_step2 {
    margin-top: 1em;
  }
}

@media screen and (max-width: 767px) {
  .form_area {
    table {
      display: block;
      margin-bottom: 0;
    }
    tbody {
      width: 100%;
      padding: 0;
      display: block;
    }
    tr {
      display: block;
      box-sizing: border-box;
      th,
      td {
        display: block;
        width: 100%;
        box-sizing: border-box;
        border: none;
      }
      th {
        background: #444;
        color: #fff;
        padding: 10px 20px;
        .form-label {
          padding: 0 0.5em;
        }
      }
      td {
        padding: 10px 0;
      }
    }
    input[type=submit] {
      width: 100%;
      font-size: 20px;
      padding: 2px;
      background: #f39800;
      border-radius: 4px;
    }
  }
}
}