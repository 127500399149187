@charset "UTF-8";

.row-0{
  margin-left:0px;
  margin-right:0px;
  > div{
    padding-right:0px;
    padding-left:0px;
  }
}
.row-10{
  margin-left:-5px;
  margin-right:-5px;
  > div{
    padding-right:5px;
    padding-left:5px;
  }
}
.row-20{
  margin-left:-10px;
  margin-right:-10px;
  > div{
    padding-right:10px;
    padding-left:10px;
    @include xs {
      padding-right:0px;
      padding-left:0px;
    };
  }
}
.row-25{
  margin-left:-12.5px;
  margin-right:-12.5px;
  > div{
    padding-right:12.5px;
    padding-left:12.5px;
  }
}
.row-30{
  margin-left:-15px;
  margin-right:-15px;
  > div{
    padding-right:15px;
    padding-left:15px;
  }
}
.row-40{
  margin-left:-20px;
  margin-right:-20px;
  > div{
    padding-right:20px;
    padding-left:20px;
  }
}
.row-50{
  margin-left:-25px;
  margin-right:-25px;
  > div{
    padding-right:25px;
    padding-left:25px;
  }
}
.row-60{
  margin-left:-30px;
  margin-right:-30px;
  > div{
    padding-right:30px;
    padding-left:30px;
  }
}
.row-70{
  margin-left:-35px;
  margin-right:-35px;
  > div{
    padding-right:35px;
    padding-left:35px;
  }
}

.cyan {color: cyan;}
.white {color: white;}
.lightgray {color: lightgray;}

.ease {
  transition: all 0.15s ease-in-out;
}

.headding_l {
  font-family: $subFontFamily;
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  padding: 10px 0;
  letter-spacing: 0.4em;
  text-indent: 0.2em;
  text-shadow: 0 5px 10px rgba(0,0,0,0.5);
  span {
    color: $accentFontColor;
  }
  &.event_detail_headding {
    padding: 110px 0 40px;
    @include xs {
      padding: 60px 0 10px;
    };
  }
  @include xs {
    font-size: 20px;
    padding: 0;
    margin: 5px auto;
    text-shadow: none;
  };
}
.headding_m {
  font-family: $subFontFamily;
  font-weight: bold;
  text-align: center;
  line-height: 1.8;
  position: absolute;
  top: 10px;
  width: 233px;
  height: 55px;
  left: 50%;
  margin-top: 0;
  margin-left: -$baseMinWidth/2-40px;
  background: url(../img/icon/bg-HM.png);
  background-size: 100% 100%;
  &:first-letter {
    color: $accentFontColor;
  }
  z-index: 2;
}
.headding_s {
  background: #333;
  color: white;
  margin: 0;
  padding: 5px 10px;
  font-size: 18px;
  font-weight: bold;
  position: relative;
  @include xs {
    font-size: 12px;
    padding: 5px 1%;
  };
  i.fa {
    color: $accent;
    font-size: 24px;
    @include xs {
      font-size: 14px;
    };
  }
  span {
    display: inline-block;
    &.ttl_label {
      position: absolute;
      top: -20px;
      left: 12px;
      width: 86px;
      height: 81px;
      background: url(../img/common/bg_ttl_label.png) no-repeat left top;
      background-size: 100% 100%;
      text-align: center;
      font-family: $subFontFamily;
      color: #333;
      line-height: 1.2;
      font-size: 22px;
      letter-spacing: 0.2em;
      text-indent: 0.2em;
      @include xs {
        display: none;
      };
    }
    &.ttl {
      margin-left: 100px;
      padding: 2px 0;
      @include xs {
        &.event_list_ttl {
          position: absolute;
          display: block;
          text-align: center;
          margin: 0;
          width: 98%;
        }
      };
    }
    &.ttl_pager {
      float: right;
      padding-right: 14px;
      a {
        display: inline-block;
        color: black;
        background: white;
        width: 70px;
        height: 28px;
        padding: 5px 0;
        position: relative;
        text-align: center;
        margin-left: 5px;
        &.ttl_prev {
          &:before {
            content: '';
            position: absolute;
            left: -28px;
            top: 0;
            width: 0;
            height: 0;
            border-left: 14px solid transparent;
            border-right: 14px solid white;
            border-top: 14px solid transparent;
            border-bottom: 14px solid transparent;
            transition: all 0.15s linear;
          }
        }
        &.ttl_next {
          &:after {
            content: '';
            position: absolute;
            right: -28px;
            top: 0;
            width: 0;
            height: 0;
            border-left: 14px solid white;
            border-right: 14px solid transparent;
            border-top: 14px solid transparent;
            border-bottom: 14px solid transparent;
            transition: all 0.15s linear;
          }
        }
        &.listview {
          padding: 5px 18px 2px;
          width: auto;
          height: auto;
          font-size: 16px;
        }
        &:hover {
          color: white;
          background: $accent;
          opacity: 1;
          &.ttl_prev:before {
            border-right: 14px solid $accent;
            transition: all 0.15s linear;
          }
          &.ttl_next:after {
            border-left: 14px solid $accent;
            transition: all 0.15s linear;
          }
        }
      }
      @include xs {
        // background: #f00;
        display: block;
        float: none;
        a {
          width: 50px;
          height: 22px;
          &.ttl_prev {
            margin-left: 15px;
            &:before {
              position: absolute;
              left: -22px;
              border-left: 11px solid transparent;
              border-right: 11px solid white;
              border-top: 11px solid transparent;
              border-bottom: 11px solid transparent;
              transition: all 0.15s linear;
            }
          }
          &.ttl_next {
            margin-right: 5px;
            float: right;
            &:after {
              position: absolute;
              right: -22px;
              border-left: 11px solid white;
              border-right: 11px solid transparent;
              border-top: 11px solid transparent;
              border-bottom: 11px solid transparent;
              transition: all 0.15s linear;
            }
          }
        }
      };
    }
  }
}
.btn {
  border: 1px solid;
  padding: 5px 15px;
  border-radius: 0;
  @include xs {
    width: 100%;
  };
}
.btn_s {
  border: 1px solid;
  padding: 15px 45px;
  border-radius: 0;
  @include xs {
    padding: 10px;
  };
}
.btn_m {
  border: 2px solid;
  font-size: 24px;
  padding: 15px 45px;
  border-radius: 0;
  position: relative;
  text-indent: 1em;
  &:before {
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f0da";
    position: absolute;
    margin-top: 0.15em;
    left: 0.5em;
  }
}
.btn_xs {
  font-size: 12px;
  border: 1px solid;
  padding: 3px 9px;
  border-radius: 0;
  @include xs {
    width: 100%;
    display: block;
    margin-bottom: 5px;
  };
}
.btn_xxs {
  font-size: 10px;
  border: 1px solid !important;
  padding: 2px 9px;
  border-radius: 0;
  margin-top: -2px;
  @include xs {
    width: 100%;
  };
}




.sp_btn {
  padding: 15px 12px 5px 13px;
  position: fixed;
  top: 0;
  right: 0;
  text-align: center;
  z-index: 1000;
  .HiddenCheckbox {
    display: none;
  }
  .MenuIcon {
    cursor: pointer;
    display: block;
    float: left;
    height: 25px;
    position: relative;
    width: 25px;
    z-index: 2;
    &::before {
      -webkit-box-shadow: #fff 0 10px 0;
      box-shadow: #fff 0 8px 0;
      height: 3px;
      -webkit-transform-origin: left top;
      -ms-transform-origin: left top;
      transform-origin: left top;
      width: 25px;
    }
    &::after {
      bottom: 6px;
      height: 3px;
      -webkit-transform-origin: left bottom;
      -ms-transform-origin: left bottom;
      transform-origin: left bottom;
      width: 25px;
    }
    &::before,
    &::after {
      background: #fff;
      display: block;
      content: '';
      position: absolute;
      -webkit-transition: -webkit-box-shadow 0.2s linear, -webkit-transform 0.4s 0.2s;
      transition: box-shadow 0.2s linear, transform 0.4s 0.2s;
    }
  }
}
.HiddenCheckbox:checked ~ .MenuHeader {
  opacity: 1;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}
.HiddenCheckbox:checked ~ .MenuIcon::before {
  -webkit-box-shadow: transparent 0 0 0;
  box-shadow: transparent 0 0 0;
  -webkit-transform: rotate(45deg) translate3d(4px, -3px, 0);
  transform: rotate(45deg) translate3d(4px, -3px, 0);
}
.HiddenCheckbox:checked ~ .MenuIcon::after {
  -webkit-transform: rotate(-45deg) translate3d(2px, 5px, 0);
  transform: rotate(-45deg) translate3d(2px, 5px, 0);
}
.HiddenCheckbox:checked ~ .Menu {
  left: 0;
}

.wp-pagenavi {
  display: table;
  width: 100%;
  @include xs {
    margin-top: 10px;
  };
  a, span {
    padding: 20px;
    @include xs {
      padding: 3px 0;
    };
    margin: 0;
    color: #666;
    display: table-cell;
    text-decoration: none;
    text-align: center;
    border-right: none;
  }
  a {
    color: #666;
  }
  a:last-child, span:last-child {
    border-right: 1px solid #999;
  }
  a:hover {
    text-decoration: none !important;
    border-color: #666 !important;
    background: #666;
    color: white;
  }
  span.current {
    border-color: #ccc !important;
  }
  span.pages {
    width: 100px;
  }
  span.pages::after {
    content: "P";
  }
}
.post_link_wrap {
  // border: 1px solid #ccc;
  padding: 10px 2%;
  width: 100%;
  background: #999;
  .post_link_ttl {
    text-align: center;
    color: white;
    margin: 0;
    font-size: 18px;
    @include xs {
      font-size: 14px;
    };
  }
  .post_link {
    display: table;
    width: 100%;
    a {
      display: table-cell;
      text-align: center;
      border: 4px solid #999;
      background: white;
      color: #666 !important;
      padding: 10px 0;
      font-size: 20px;
      @include xs {
        display: block;
        font-size: 16px;
        padding: 5px 0;
        border-width: 2px;
      };
      &:hover {
        opacity: 1 !important;
        color: white !important;
        border-color: white;
        background: #666;
      }
    }
  }
}
.pagelink {
  text-align: center;
  font-weight: bold;
  font-family: $subFontFamily;
  letter-spacing: 0.1em;
  font-size: 20px;
  a {
    border: 1px solid;
    padding: 2px 8px;
    &:hover {
      background: $accent;
      border-color: $accent;
      color: white;
      opacity: 1 !important;
    }
  }
}
.tribe-events-before-html {
  margin: 10px 0;
}
.tribe-events-list-separator-month {
  margin: 0 auto;
}
.tribe-events-list-separator-month+.type-tribe_events.tribe-events-first {
  padding-top: 0;
}
.tribe-events-loop hr {
  margin: 0;
}
.sche_view_wrap {
  display: none;
  overflow: hidden;
  .sche_view {
    float: right;
    width: 210px;
    text-align: center;
    border: none;
    th {
      text-align: center;
      padding: 5px;
      border: none;
      &.sche_view_ttl {
        background: #eee;
      }
    }
    td {
      border: none;
      padding: 5px;
      width: 150px;
      &.sche_view_showbtn {
        background: #ddd;
      }
      &.sche_view_btn {
        display: none;
        background: #eee;
        a {
          color: #999;
          &:hover {
            color: #333;
            opacity: 1;
          }
        }
      }
    }
  }
}
// tribe-events
#tribe-events-content {
  // .tribe-events-sub-nav {display: none;}
  .tribe-events-ical {display: none !important;}
}
.tribe-events-back {
  @include xs {
    display: none;
  };
}
.tribe-events-single-event-title {
  font-size: 20px;
  @include xs {
    font-size: 16px;
    margin: 5px 0 0;
  };
}
.tribe-events-schedule {
  h4 {
    font-size: 14px;
  }
}
.tribe-events-event-image {
  @include xs {
    display: block !important;
    float: none !important;
    margin: 0 auto;
    text-align: center;
  };
}
.tribe-events-list-event-title {
}
.tribe-events-list .type-tribe_events h2 {
  @include xs {
    font-size: 12px;
    margin: 0 5px;
    display: block;
  };
}
.tribe-events-list .tribe-events-venue-details {
  @include xs {
    font-size: 10px;
  };
}
.tribe-event-schedule-details {
  margin-bottom: 5px;
  span {
    font-size: 20px;
    background: #333;
    color: white;
    padding: 2px 20px;
    @include xs {
      font-size: 10px;
    };
  }
}
.tribe-events-loop .tribe-events-event-meta {
  padding: 5px 0;
}
.tribe-events-list .tribe-events-event-image {
  width: 20%;
}
.tribe-events-list .tribe-events-event-image+div.tribe-events-content {
  width: 77%;
  @include xs {
    width: 100%;
    font-size: 11px;
  };
}
#tribe-events .tribe-events-single-event-description.tribe-events-content {
  @include xs {
    width: 100%;
    margin: 0 !important;
    float: none;
  };
  h4 {
    font-size: 16px;
  }
}
#tribe-events .tribe-events-loop .tribe-events-content p {
  @include xs {
    margin: 5px !important;
  };
}
#tribe-events .tribe-events-content p, .tribe-events-after-html p, .tribe-events-before-html p {
  @include xs {
    font-size: 12px;
  };
}
#tribe-events-content .btn {
  @include xs {
    padding: 5px;
    font-size: 12px;
    font-weight: bold;
    background: $accent;
    color: white;
  };
}
.tribe-events-loop .tribe-events-event-meta {
  @include xs {
    margin-top: 0;
    margin-bottom: 5px;
  };
}
.tribe-events-list-separator-month+.type-tribe_events.tribe-events-first {
  @include xs {
    padding-bottom: 0;
  };
}
.tribe-events-list .type-tribe_events {
  @include xs {
    padding: 5px 0 0;
  };
}
.tribe-events-list-separator-month {
  @include xs {
    font-size: 14px;
    padding: 0;
  };
}
h2.tribe-events-page-title {
  @include xs {
    font-size: 18px;
  };
}
.tribe-events-calendar thead th {
  background: #333;
  @include xs {
    padding: 7px 0;
  };
}
.tribe-events-category-pickup {
  padding: 2px;
  border-bottom: 1px solid #eee;
  margin-bottom: 0 !important;
  h3.tribe-events-month-event-title {
    font-size: 10px !important;
  }
}
.tribe-events-category- {
  padding: 2px;
  border-bottom: 1px solid #eee;
  margin-bottom: 0 !important;
  h3.tribe-events-month-event-title {
    font-size: 10px !important;
  }
}
#tribe-events-content .tribe-events-calendar td {
  padding: 0;
}
.tribe-events-calendar div[id*=tribe-events-daynum-], .tribe-events-calendar div[id*=tribe-events-daynum-] a {
  background: #333;
}
.detail_list {
  th,td {
    @include xs {
      display: block;
      padding: 5px;
    };
  }
  th {
    @include xs {
      padding-bottom: 0;
      font-weight: bold;
      @include fontAwesome('\f024',inherit,inherit);
      color: #666;
    };
  }
  td {
    @include xs {
      font-size: 12px;
    };
  }
}
.tribe-events-event-meta .column, .tribe-events-event-meta .tribe-events-meta-group {
  @include xs {
    padding: 5px 2%;
    h3.tribe-events-single-section-title {
      font-size: 16px;
      margin: 0;
    }
    dl {
      margin: 0;
      dd {
        font-size: 12px;
      }
    }
  };
}
.tribe-events-sub-nav li a {
  @include xs {
    display: inline;
    padding: 0;
    color: #333 !important;
    white-space: normal;
    position: relative;
    margin-bottom: 0;
    background: inherit;
  };
}
#tribe-events-footer .tribe-events-sub-nav .tribe-events-nav-next,
#tribe-events-footer .tribe-events-sub-nav .tribe-events-nav-previous,
#tribe-events-header .tribe-events-sub-nav .tribe-events-nav-next,
#tribe-events-header .tribe-events-sub-nav .tribe-events-nav-previous {
  @include xs {
    width: auto;
  };
}
#tribe-events-footer:before,
#tribe-events-header:before,
#tribe-events-footer:after,
#tribe-events-header:after {
  @include xs {
    content: none !important;
    display: none !important;
  };
}
.tribe-bar-disabled {
  display: none;
}
.tribe-events-day .tribe-events-loop {
  max-width: 100%;
}
//====================================================
//  event_container
//====================================================
.event_container {
  margin: 20px 0;
  @include xs {
    margin: 5px 0 0;
  };
  .event_row {
    overflow: hidden;
    border-top: 1px solid #666;
    padding: 10px 0;
    @include xs {
      padding: 5px 0;
    };
    &.border_none {
      border: none;
      padding: 0 0 10px;
      @include xs {
        border-top: 1px dotted #999;
        padding: 2px 0 5px;
      };
    }
    // event_date
    .event_date {
      width: 16%;
      font-weight: bold;
      color: #666;
      float: left;
      letter-spacing: -0.1em;
      .month {
        font-size: 18px;
      }
      .date {
        font-size: 30px;
      }
      .day {
        display: inline-block;
        text-indent: -0.5em;
        font-size: 16px;
      }
      @include xs {
        width: 60px;
        background: #666;
        text-align: center;
        color: white;
        padding-top: 2px;
        float: none;
        .month {
          font-size: 10px;
        }
        .date {
          display: inline-block;
          text-indent: -0.2em;
          font-size: 12px;
        }
        .day {
          font-size: 10px;
        }
      };
    }
    &.border_none .event_date {display: none;}
    // event_detail
    .event_detail {
      width: 84%;
      float: right;
      margin-bottom: 10px;
      @include xs {
        width: 100%;
        float: none;
        margin: 2px 0 0;
      };
      .event_ttl {
        a {
          color: #666;
          font-size: 18px;
          font-weight: bold;
          @include xs {
            font-size: 14px;
          };
          &:hover {
            color: $accent;
            opacity: 1;
          }
        }
      }
      .event_cont {
        background: #eee;
        padding: 10px 10px;
        position: relative;
        @include xs {
          padding: 5px 0;
        };
        .event_cont_table {
          th,td {
            border: none;
            padding: 5px 0;
            vertical-align: top;
            @include xs {
              padding: 2px 0;
            };
          }
          th {
            font-size: 13px;
            width: 20%;
            font-weight: bold;
            @include xs {
              font-size: 12px;
            };
          }
          td {
            font-size: 13px;
            @include xs {
              font-size: 12px;
            };
            &.btn_wrap {
              span {
                display: block;
              }
              padding-top: 20px;
              @include xs {
                padding: 2px 2% 0;
                span {
                  display: none;
                }
              };
            }
          }
          &.l-boxl {
            width: 45%;
            @include xs {
              float: none;
              width: 100%;
            };
          }
          &.l-boxr {
            width: 55%;
            @include xs {
              float: none;
              width: 100%;
            };
            th {
              width: 35%;
            }
          }
          .btn {
            background: url(../img/common/btn_arrow.png) no-repeat 0 0;
            background-size: 100% 100%;
            width: 207px;
            height: 47px;
            border: none;
            color: white;
            box-sizing: border-box;
            font-family: $subFontFamily;
            font-weight: bold;
            font-size: 20px;
            line-height: 1.7em;
            position: absolute;
            right: 10px;
            bottom: 10px;
            @include xs {
              height: auto;
              line-height: 1.0em;
              margin: 0 auto;
              position: inherit;
              display: block;
              background: $accent;
              width: 100%;
            };
          }
        }
      }
    }
  }
  // event_pager
  .event_pager {
    border-top: 1px solid #666;
    padding: 20px 0;
    overflow: hidden;
    @include xs {
      padding: 5px 0;
    };
    a {
      display: inline-block;
      text-align: center;
      box-sizing: border-box;
      line-height: 3.0em;
      width: 135px;
      height: 50px;
      font-size: 18px;
      font-weight: bold;
      color: #666;
      @include xs {
        line-height: 2.6em;
        font-size: 12px;
        width: 81px;
        height: 30px;
      };
      &.prev {
        background: url(../img/common/prev.png) no-repeat 0 0;
        background-size: 100% 100%;
        text-indent: 1em;
      }
      &.next {
        float: right;
        background: url(../img/common/next.png) no-repeat 0 0;
        background-size: 100% 100%;;
        text-indent: -1em;
      }
    }
  }
}
.calendar_nav_wrap {
  text-align: center;
  a {
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #555;
    color: white !important;
    line-height: 2.8em;
    font-size: 18px;
    margin: 10px 5px 0;
    box-shadow: 0px 4px 4px #ccc;
    small {
      font-size: 14px;
    }
    &:hover {
      background: $accent;
      opacity: 1 !important;
      transform: scale(1.1);
    }
    &.active {
      background: white;
      color: #555 !important;
      font-weight: bold;
      border: 2px solid #555;
      &:hover {
        color: $accent !important;
        font-weight: bold;
        border: 2px solid $accent;
      }
    }
  }
}

// ===========================================
// switch br
// ===========================================
.br-pc{
  display: inline;
}

.br-sp{
  display: none;
}

@include xs{
  .br-pc{
    display: none;
  }
  
  .br-sp{
    display: inline;
  }
}

//===========================
//contact-check-box
//===========================
.privacy-consent {
  color: #666666;
  display: flex;
}

.privacypolicy-agreebox {
  width: 10px;
}

.privacy-link {
  text-decoration: underline;
  color: #666666;
  margin-left: 10px;
}

.privacy-link:hover {
  color: white;
  opacity: 0.7;
}

.required-style {
  border: solid 1px #666666;
  padding: 0 1px;
  height: 20px;
}

@media screen and (max-width: 767px) {
  .privacypolicy-agreebox {
    width: 20px;
  }
  .privacy-link {
    margin-left: 5px;
  }
}