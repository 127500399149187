.keyv {
    background: url(../img/audition/keyv.jpg) no-repeat center center;
    background-size: cover;
    width: 100%;
    height: 100vh;
    .pt {
        background: url(../img/common/pattern.png) repeat;
        background-attachment: fixed;
        width: 100%;
        height: 100%;
        padding-top: 10px;
    }
    .logo {
        width: 309px;
        margin: 150px auto 0;
        text-align: center;
        img {
            width: 100%;
            // animation-name: anime1;
            // animation-duration: 2.8s;
            // animation-timing-function: ease-in-out;
            // animation-iteration-count: infinite;
            // @keyframes anime1 {
            //   0% {background: blue;}
            //   30% {background: green;}
            //   50% {background: orange;}
            //   70% {background: red;}
            //   100% {background: purple;}
            // }
        }
        span {
            display: block;
            &.logotype_s {
                margin-top: 30px;
                font-size: 20px;
            }
            &.logotype_xs {
                font-family: $subFontFamily;
                letter-spacing: 0.1em;
                font-size: 16px;
                line-height: 1.8em;
                margin-top: 10px;
                img {
                    width: 40px;
                }
            }
        }
        @include xs {
            width: 200px;
            margin: 50px auto 0;
            span {
                &.logotype_s {
                    margin-top: 10px;
                    font-size: 13px;
                }
                &.logotype_xs {
                    font-size: 13px;
                    line-height: 1.8em;
                    margin-top: 5px;
                    img {
                        width: 30px;
                    }
                }
            }
        }
        ;
    }
    .lead {
        width: 700px;
        margin: 30px auto 0;
        font-size: 16px;
        line-height: 1.8em;
        @include xs {
            width: 94%;
            font-size: 12px;
            line-height: 1.5em;
            padding: 5px 2%;
            background: rgba(0, 0, 0, 0.4);
            border: 1px solid rgba(255, 255, 255, 0.2);
            margin: 20px auto 0;
        }
        ;
        em {
            font-weight: bold;
            color: $accentFontColor;
        }
    }
}

//====================================================
//  audition_post
//====================================================
.audition_post {
    background: white;
    border-bottom: 1px solid $accent;
    img {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .btn {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    @include sm {
        border-bottom: 2px solid #ccc;
        &.detailbox .r_cont {
            margin-bottom: 30px;
        }
        .box {
            overflow: hidden;
        }
        h2 {
            font-size: 20px;
            font-weight: bold;
            &:before {
                display: none;
            }
        }
        h3 {
            font-size: 25px;
            font-weight: bold;
            padding-bottom: 10px;
            &:before {
                display: none;
            }
        }
        p {
            font-size: 20px;
        }
        img.alignleft {
            float: left;
            padding: 20px 10px 0px 0px;
            width: 48%;
            height: auto;
            box-sizing: border-box;
        }
        .align_left {
            float: left;
            padding: 10px 10px 0px 0px;
            width: 50%;
            height: auto;
            box-sizing: border-box;
        }
        img.alignright {
            float: left;
            padding: 20px 0px 0px 10px;
            width: 48%;
            height: auto;
            box-sizing: border-box;
        }
        .align_right {
            float: right;
            padding: 10px 0px 0px 10px;
            width: 50%;
            height: auto;
            box-sizing: border-box;
        }
    }
    ;
}

//====================================================
//  tournament
//====================================================
#tournament {
    padding-top: 10px;
    background: white;
    text-align: center;
    .headding_l {
        color: #333;
    }
    .tournament_map {
        width: 100%;
        height: 514px;
        background: url(../img/audition/tournament_map.png) no-repeat center top;
        position: relative;
        @include xs {
            width: 100%;
            height: auto;
            background: none;
        }
        ;
        div {
            position: absolute;
            line-height: 1.2em;
            z-index: 1;
            @include xs {
                position: static;
            }
            ;
            &.tournament_ttl {
                top: 40px;
                left: 730px;
                font-size: 36px;
                color: #333;
                text-align: left;
                @include xs {
                    font-size: 20px;
                    font-weight: bold;
                    text-align: center;
                }
                ;
            }
            &.tournament_lead {
                top: 140px;
                left: 730px;
                font-size: 20px;
                color: #333;
                text-align: left;
                @include xs {
                    font-size: 16px;
                    text-align: center;
                }
                ;
            }
            &.grandprix {
                top: 170px;
                left: 50%;
                margin-left: -50px;
                img {
                    width: 100px;
                    border: 4px solid $accent;
                }
            }
            &.victory_line {
                top: 214px;
                left: 50%;
                margin-left: -350px;
                z-index: 0;
            }
            &.mid_line {
                top: 410px;
                left: 50%;
                z-index: 0;
                &.mid_line_a {
                    margin-left: -446px;
                }
                &.mid_line_b {
                    margin-left: -102px;
                }
                &.mid_line_c {
                    margin-left: 250px;
                }
            }
            &.a_winner {
                top: 378px;
                left: 50%;
                margin-left: -400px;
                img {
                    width: 100px;
                    border: 4px solid $accent;
                }
            }
            &.b_winner {
                top: 378px;
                left: 50%;
                margin-left: -50px;
                img {
                    width: 100px;
                    border: 4px solid $accent;
                }
            }
            &.c_winner {
                top: 378px;
                left: 50%;
                margin-left: 300px;
                img {
                    width: 100px;
                    border: 4px solid $accent;
                }
            }
        }
    }
    .block_wrap {
        color: #333;
        position: relative;
        height: 260px;
        @include xs {
            height: auto;
        }
        ;
        a {
            color: #666;
        }
        .block {
            position: absolute;
            width: 260px;
            @include xs {
                width: 300px;
                padding: 10px;
                background: #eee;
                position: static;
                margin: 0 auto 5px;
            }
            ;
            &.block_l {
                left: 8.4%;
            }
            &.block_c {
                left: 50%;
                @include sm {
                    margin-left: -130px;
                }
                ;
            }
            &.block_r {
                right: 8.4%;
            }
            .block_artist_img {
                overflow: hidden;
                img {
                    display: inline-block;
                    width: 120px;
                    height: 90px;
                    border: 4px solid #ccc;
                    &.win {
                        border-color: $accent;
                    }
                }
            }
            .block_artist_name {
                margin-top: 5px;
                overflow: hidden;
                span {
                    display: inline-block;
                    width: 120px;
                    font-size: 16px;
                    font-weight: bold;
                }
            }
            .btn_area {
                overflow: hidden;
                .btn {
                    width: 120px;
                    border-width: 2px;
                    padding: 3px;
                    position: relative;
                    &:before {
                        content: "\f0da";
                        display: inline-block;
                        font: normal normal normal 14px/1 FontAwesome;
                        font-size: 15px;
                        text-rendering: auto;
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;
                        position: absolute;
                        left: 0.5em;
                    }
                    &:hover {
                        color: white;
                        border-color: $accent;
                        background: $accent;
                    }
                }
            }
            .block_label {
                display: block;
                font-weight: bold;
                font-size: 30px;
                font-family: $subFontFamily;
                color: #333;
                letter-spacing: 0.5em;
                text-indent: 0.25em;
                &:hover {
                    color: $accent;
                }
            }
            .block_season {
                display: block;
                padding: 2px 10px;
                color: white;
                background: #666;
                &:hover {
                    background: $accent;
                }
            }
        }
    }
}

.tournament_info {
    position: relative;
    @include xs {
        .container {
            padding-left: 5px;
            padding-right: -5px;
        }
    }
    ;
    &:before {
        content: '';
        position: absolute;
        top: -40px;
        width: 0;
        height: 0;
        border-left: 40px solid transparent;
        border-right: 40px solid transparent;
        border-bottom: 40px solid #666;
        @include xs {
            display: none;
        }
        ;
    }
    &.a_block {
        &:before {
            left: 50%;
            margin-left: -375px;
        }
    }
    &.b_block {
        &:before {
            left: 50%;
            margin-left: -40px;
        }
    }
    &.c_block {
        &:before {
            right: 50%;
            margin-right: -385px;
        }
    }
    background: #666;
    .tournament_info_lead {
        margin: 30px auto;
        h1 {
            font-size: 26px;
        }
        @include xs {
            margin: 10px auto 0;
            padding-right: 5px;
            h1 {
                font-size: 18px;
                margin: 10px 0 5px;
                padding-bottom: 5px;
                border-bottom: 1px solid #ccc;
            }
            p {
                font-size: 12px;
                margin: 0;
            }
        }
        ;
    }
    .tournament_info_artist_wrap {
        margin: 30px auto;
        .tournament_info_artist {
            .tournament_info_artist_img {
                width: 100%;
                img {
                    width: 100%;
                }
            }
            .artist_name {
                font-size: 20px;
                margin-top: 10px;
                small {
                    font-size: 14px;
                }
            }
            .artist_introduce {
                font-size: 12px;
            }
            .btn {
                width: 220px;
                border-width: 2px;
                padding: 3px;
                position: relative;
                &:before {
                    content: "\f0da";
                    display: inline-block;
                    font: normal normal normal 14px/1 FontAwesome;
                    font-size: 15px;
                    text-rendering: auto;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    position: absolute;
                    left: 0.5em;
                }
                &:hover {
                    color: white;
                    border-color: $accent;
                    background: $accent;
                }
            }
        }
        @include xs {
            margin: 5px auto 0;
            padding: 5px 10px 5px 20px;
            box-sizing: border-box;
            .tournament_info_artist {
                overflow: hidden;
                border-top: 1px solid #ccc;
                padding: 5px 0;
                .tournament_info_artist_img {
                    width: 26vw;
                    float: left;
                    margin-right: 5px;
                    margin-bottom: 5px;
                }
                .artist_name {
                    font-size: 16px;
                    margin-top: 0;
                    small {
                        font-size: 10px;
                    }
                }
                .artist_introduce {
                    font-size: 10px;
                }
                .btn {
                    width: 58vw;
                    float: right;
                }
            }
        }
        ;
    }
}

//====================================================
//  fmf_shedule & victory
//====================================================
.fmf_list {
    background: white;
    color: #333;
    padding: 20px 0;
    @include xs {
        padding: 0 0 10px;
        .container {
            padding-left: 5px;
            padding-right: 5px;
        }
    }
    ;
    .heading_s {
        color: white;
        background: #333;
        padding: 3px 30px;
        span {
            font-size: 26px;
            font-family: $subFontFamily;
            letter-spacing: 0.4em;
        }
        small {
            font-size: 18px;
            margin-left: 20px;
            color: white;
        }
        @include xs {
            margin: 10px 0 0;
            padding: 3px 0;
            text-align: center;
            span {
                font-size: 16px;
                display: block;
                margin: 0 auto;
            }
            small {
                font-size: 14px;
                display: block;
                margin: 0 auto;
            }
        }
        ;
    }
    .sche_month {
        @include clearfix;
        border-bottom: 1px solid #333;
        padding: 20px 0;
        @include xs {
            padding: 5px 0 0;
        }
        ;
        .month_label {
            width: 140px;
            height: 140px;
            background: url(../img/common/round-140-140-x2.png) no-repeat;
            background-size: 100% 100%;
            color: white;
            text-align: center;
            font-size: 26px;
            padding: 35px 0 0;
            box-sizing: border-box;
            float: left;
            small {
                display: block;
                font-size: 14px;
                font-weight: bold;
                font-family: $subFontFamily;
                letter-spacing: 0.2em;
            }
            .num {
                font-size: 36px;
            }
            @include xs {
                width: auto;
                height: auto;
                background: #999;
                padding: 10px 5px 5px;
                line-height: 0.5em;
                font-size: 16px;
                small,
                .num {
                    font-size: 16px;
                    display: inline-block;
                }
                small {
                    font-size: 10px;
                }
            }
            ;
        }
        table {
            width: 380px;
            float: right;
            border: none;
            th {
                font-size: 22px;
                font-weight: bold;
                border: none;
                padding: 0;
            }
            .time {
                font-size: 18px;
                border: none;
                padding: 0;
            }
            .artist_name {
                font-size: 26px;
                border: none;
                padding: 0;
            }
            @include xs {
                width: 100%;
                float: none;
                th {
                    font-size: 16px;
                    text-align: center;
                }
                .time {
                    font-size: 12px;
                }
                .artist_name {
                    font-size: 18px;
                }
            }
            ;
        }
    }
    .asianbeat_message_wrap {
        padding: 20px 0 0 40px;
        @include xs {
            padding: 0;
        }
        ;
        .asianbeat_message {
            background: #666;
            padding: 20px 40px;
            color: white;
            .asianbeat_lead {
                font-size: 22px;
                font-weight: bold;
            }
            .asianbeat_term {
                background: white;
                color: #666;
                font-size: 22px;
                font-weight: bold;
                padding-left: 5px;
            }
            .asianbeat_ttl {
                font-size: 36px;
                font-weight: bold;
                margin: 5px 0 0;
                &:before {
                    content: "";
                    display: inline-block;
                    width: 20px;
                    height: 26px;
                    background: white;
                    @include xs {
                        width: 10px;
                        height: 18px;
                    }
                    ;
                }
                small {
                    font-size: 22px;
                    font-weight: bold;
                    margin-left: 20px;
                }
            }
            .btn {
                width: 100%;
                font-size: 24px;
                border-width: 2px;
                background: #444;
                padding: 10px 0;
                @include xs {
                    font-size: 14px;
                }
                ;
                &:before {
                    content: "\f0da";
                    display: inline-block;
                    font: normal normal normal 14px/1 FontAwesome;
                    font-size: inherit;
                    text-rendering: auto;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    margin-right: 1em;
                }
                &:hover {
                    opacity: 1;
                    color: $accent;
                    background: white;
                }
            }
            .asianbeat_introduction {
                font-size: 16px;
                line-height: 1.2em;
                margin: 10px 0;
                b {
                    font-size: 20px;
                    line-height: 1.4em;
                }
                &.bb {
                    padding-bottom: 10px;
                    border-bottom: 1px solid white;
                }
            }
            .sub_lead {
                font-size: 24px;
                font-weight: bold;
                line-height: 1.2em;
            }
            @include xs {
                margin-top: 5px;
                padding: 5px 4%;
                .asianbeat_lead {
                    font-size: 16px;
                }
                .asianbeat_term {
                    font-size: 16px;
                }
                .asianbeat_ttl {
                    font-size: 22px;
                }
                .btn {
                    font-size: 14px;
                    &:before {
                        height: 18px;
                    }
                    small {
                        font-size: 16px;
                    }
                }
                .asianbeat_introduction {
                    font-size: 13px;
                    b {
                        font-size: 14px;
                    }
                }
                .sub_lead {
                    font-size: 16px;
                }
            }
            ;
        }
    }
    .fmf_place {
        margin: 20px 0;
        @include xs {
            margin: 10px 0 0;
        }
        ;
        .place_lead {
            font-size: 26px;
            .place_label {
                display: inline-block;
                width: 140px;
                height: 50px;
                margin-right: 20px;
                background: url(../img/audition/label-140-50-x2.png) no-repeat;
                background-size: 100% 100%;
                color: white;
                text-align: center;
                font-size: 26px;
                letter-spacing: 1em;
                line-height: 2em;
            }
            @include xs {
                font-size: 13px;
                .place_label {
                    width: 70px;
                    height: 25px;
                    font-size: 13px;
                }
            }
            ;
        }
        .place_address {
            font-size: 16px;
            a {
                color: $accent;
                &:hover {
                    opacity: 0.7;
                }
            }
            @include xs {
                float: none;
                font-size: 14px;
                br {
                    display: none;
                }
            }
            ;
        }
    }
}

#victory {
    .victory_ttl {
        overflow: hidden;
        .crown {
            display: block;
            width: 80px;
            float: left;
            margin-right: 20px;
        }
        .txt {
            display: block;
            padding-top: 20px;
            font-size: 18px;
            b {
                font-size: 30px;
            }
        }
    }
    @include xs {
        .victory_ttl {
            position: absolute;
            top: 20px;
            left: 32vw;
            overflow: inherit;
            .crown {
                width: 40px;
                position: absolute;
                top: -34px;
                left: 0;
            }
            .txt {
                padding-top: 5px;
                font-size: 14px;
                b {
                    font-size: 18px;
                }
            }
        }
    }
    ;
    .victory_wrap {
        margin-bottom: 40px;
        @include xs {
            position: relative;
            margin-bottom: 0;
            padding-bottom: 10px;
            border-bottom: 1px solid #ccc;
        }
        ;
    }
    .victory_cont {
        font-size: 18px;
        width: 660px;
        margin: 20px 0 20px;
        @include xs {
            margin: 0;
            width: 98%;
            font-size: 12px;
        }
        ;
    }
    .btn {
        font-size: 24px;
        color: #333;
        padding: 5px 30px;
        border-width: 2px;
        @include xs {
            font-size: 14px;
        }
        ;
        &:before {
            content: "\f0da";
            display: inline-block;
            font: normal normal normal 14px/1 FontAwesome;
            font-size: inherit;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            margin-right: 0.5em;
        }
        &:hover {
            opacity: 1;
            color: $accent;
        }
    }
    .artist_img {
        margin-top: 70px;
        width: 400px;
        img {
            width: 390px;
            margin: 5px;
            box-shadow: 0 0 10px #999;
        }
        @include xs {
            margin-top: 10px;
            width: 30vw;
            float: left;
            img {
                width: 100%;
                margin: 0;
                box-shadow: none;
                border: 1px solid #eee;
            }
        }
        ;
    }
}

//====================================================
//  entry
//====================================================
#entry {
    background: #666;
    padding-top: 10px;
    .entry_cont {
        .entry_cont_ttl {
            width: 360px;
            height: 100px;
            background: url(../img/audition/entry_cont_ttl.png) no-repeat;
            background-size: 100% 100%;
            box-sizing: border-box;
            padding: 10px 0 0 15px;
            color: $accent;
            line-height: 1.3em;
            font-size: 28px;
            font-weight: bold;
            span {
                font-family: $subFontFamily;
                font-size: 24px;
                letter-spacing: 0.2em;
                color: white;
            }
            @include xs {
                width: 100%;
                height: 25px;
                font-size: 16px;
                padding-top: 3px;
                background-size: 90px 100%;
                margin: 0;
                br {
                    display: none;
                }
                span {
                    font-size: 10px;
                    margin-right: 25px;
                    line-height: 1.3em;
                    display: inline-block;
                    vertical-align: middle;
                }
            }
            ;
        }
        .entry_box {
            border-left: 2px dotted white;
            .entry_cont_detail {
                font-size: 18px;
                height: 150px;
                margin: 20px 18px;
                box-sizing: border-box;
            }
            .entry_block {
                font-size: 22px;
                height: 100px;
                margin: 20px 18px;
                box-sizing: border-box;
                border-bottom: 1px solid #ccc;
                &.pattern2 {
                    padding-top: 20px;
                }
                &.pattern3 {
                    padding-top: 10px;
                }
                b {
                    font-size: 30px;
                }
            }
            @include xs {
                .entry_cont_detail {
                    font-size: 13px;
                    height: auto;
                    margin: 10px;
                }
                .entry_block {
                    font-size: 14px;
                    height: auto;
                    margin: 10px 10px;
                    &.pattern2 {
                        padding-top: 0px;
                    }
                    &.pattern3 {
                        padding-top: 0px;
                    }
                    b {
                        font-size: 16px;
                    }
                }
            }
            ;
        }
    }
    .recruit {
        border: 2px solid white;
        padding: 30px;
        margin: 30px auto 50px;
        .recruit_ttl {
            font-size: 30px;
        }
        .recruit_subttl {
            font-size: 24px;
        }
        .recruit_cont_box {
            overflow: hidden;
            .recruit_cont {
                margin-top: 10px;
                font-size: 16px;
                float: left;
                line-height: 1.2em;
            }
            .btn {
                float: right;
                font-size: 24px;
                border-width: 2px;
                background: #333;
                margin-top: 1.2em;
                &:before {
                    content: "\f0da";
                    display: inline-block;
                    font: normal normal normal 14px/1 FontAwesome;
                    font-size: inherit;
                    text-rendering: auto;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    margin-right: 0.5em;
                }
                &:hover {
                    color: $accent;
                    background: white;
                }
            }
        }
        @include xs {
            border-width: 1px;
            padding: 10px;
            margin: 10px auto;
            .recruit_ttl {
                font-size: 16px;
                font-weight: bold;
            }
            .recruit_subttl {
                font-size: 14px;
            }
            .recruit_cont_box {
                .recruit_cont {
                    font-size: 12px;
                    float: none;
                }
                .btn {
                    float: none;
                    font-size: 13px;
                    border-width: 1px;
                    margin-top: 5px;
                }
            }
        }
        ;
    }
}