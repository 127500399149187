//====================================================
//  header
//====================================================
header {
  font-family: $subFontFamily;
  background: #222;
  background: rgba(0,0,0,0.6);
  box-shadow: 0 5px 5px rgba(0,0,0,0.1);
  z-index: 2000;
  width: 100%;
  bottom: auto;
  height: auto;
  @include sm (){
    position: absolute;
    bottom: 0;
    height: 75px;
  };
  @include xs (){
    position: fixed;
    top: 0;
  };
  .brand_logo {
    display: flex;
    align-items: center;
    a {
      display: block;
      float: left;
      img {
        width: 100%;
      }
      &:hover {
        color: inherit;
        img {
          opacity: inherit;
        }
      }
    }
    .mff_logo {
      display: inline-block;
      width: 17%;
    }
    .fmf_logo {
      display: inline-block;
      width: 83%;
      span {
        display: block;
        margin-left: 5%;
        &.brand_name {
          font-size: 20px;
          font-weight: bold;
        }
      }
    }
  }
}

//====================================================
//  top
//====================================================
#particles {
  position: absolute;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  .slick-slider .slick-list {
    overflow: hidden;
  }
}
.pg-canvas {
  z-index: 20;
  opacity: 0.55;
  position: absolute;
  top:0;
  width: 100%;
}
.bg_pt {
  height: 100vh;
  background: url(../img/index/pattern.png);
  background-attachment: fixed;
  z-index: 12;
  position: absolute;
  top:0;
  width: 100%;
}
.bg_top {
  height: 100vh;
  background-size: cover;
  z-index: 9;
  position: absolute;
  top:0;
  bottom: 0;
  left:0;
  right: 0;
  width: 100%;
  backface-visibility:hidden;
  .slides {
    width: 100%;
    height: 100vh;
    backface-visibility:hidden;
    position: relative;
    &.bg1 {
      background: url(../img/index/bg_top01.jpg);
    }
    &.bg2 {
      background: url(../img/index/bg_top02.jpg);
    }
    &.bg3 {
      background: url(../img/index/bg_top03.jpg);
    }
    &.bg4 {
      background: url(../img/index/bg_top04.jpg);
    }
    background-attachment: fixed;
    background-size: cover;
    background-position: center bottom;
    background-repeat: no-repeat;
  }
  @include xs {
    background: url(../img/index/bg_sp.jpg) repeat-y center bottom;
    background-size: 100% auto;
    .slides {
      display: none;
    }
  };
}
.top {
  height: 100vh;
  position: relative;
  z-index: 30;
  .logo {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -200px 0 0 -157px;
    z-index: 15;
    span {
      display: block;
      text-align: center;
      font-family: $subFontFamily;
      font-weight: bold;
      letter-spacing: 0.4em;
      &.logotype_s {
        font-size: 36px;
        margin-top: 20px;
        @include xs (){
          font-size: 20px;
        };
      }
      &.logotype_m {
        font-size: 41px;
        @include xs (){
          font-size: 24px;
        };
      }
      &.logotype_xs {
        font-size: 16px;
        line-height: 1.9em;
        letter-spacing: 0.2em;
        text-indent: -1em;
        margin-top: 14px;
        img {
          width: 40px;
        }
        @include xs (){
          font-size: 10px;
          img {
            width: 26px;
          }
        };
      }
    }
  }
  @include xs (){
    .logo {
      margin: -140px 0 0 -100px;
      img {
        width: 200px;
      }
    }
  };
}


//====================================================
//  nav
//====================================================
.subnav {
  display: none;
  position: absolute;
  z-index: 10;
  width: 180px;
  left: 50%;
  bottom: 77px;
  margin-left: -90px;
  padding: 0;
  &:after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -10px;
    margin-left: -10px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid rgba(0,0,0,0.9);
  }
  li {
    margin: 0;
    padding: 0;
    width: 100%;
    border-top: 0;
    a {
      display: block;
      width: 100%;
      position: relative;
      margin: 0;
      padding: 15px 5px;
      font-weight: 400;
      text-align: left;
      font-size: 12px;
      background: rgba(0,0,0,0.9);
      border-top: 1px solid #333;
      font-size: 14px;
      text-align: center;
    }
  }
}
@include xs {
  .subnav {
    bottom: auto;
    top: 77px;
    &:before {
      content: '';
      position: absolute;
      left: 50%;
      top: -10px;
      margin-left: -10px;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid rgba(0,0,0,0.9);
    }
    &:after {
      display: none;
    }
    li {
      a {
        border-top: none;
        border-bottom: 1px solid #333;
      }
    }
  }
};
.navbar-fixed-top {
  .subnav {
    bottom: auto;
    top: 77px;
    &:before {
      content: '';
      position: absolute;
      left: 50%;
      top: -10px;
      margin-left: -10px;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid rgba(0,0,0,0.9);
    }
    &:after {
      display: none;
    }
    li {
      a {
        border-top: none;
        border-bottom: 1px solid #333;
      }
    }
  }
}
.nav_wrap {
  // width: 820px\9;
  overflow: hidden\9;
  float: right\9;
  nav {
    display: table;
    float: right;
    display: block\9;
    // width: 570px\9;
    // position: fixed\9;
    top: 0;
    right: 0;
    .navbtn {
      position: relative;
    }
    > a,.navbtn span {
      font-size: 15px;
      display: table-cell;
      padding: 25px 10px;
      font-weight: bold;
      cursor: pointer;
      display: block\9;
      float: left\9;
      &:hover {
        color: $accentFontColor;
      }
    }
  }
  form {
    margin: 15px 0 15px 10px;
    padding-left: 20px;
    border-left: 1px solid #ccc;
    opacity: 0.8;
    float: right;
    input {
      width: 60px;
      background: none;
      border: none;
      padding: 13px 5px;
      &:focus {
        opacity: 1;
        width: 150px;
        width: 120px\9;
      }
    }
    &:hover {
      opacity: 1;
    }
  }
}
@include xs (){
  header {
    background: rgba(0,0,0,0.9);
    h1 {
      margin-top: 10px;
      margin-bottom: 0px;
    }
    .brand_logo {
      .mff_logo {
        width: 50px;
      }
      .fmf_logo {
        width: 230px;
        span {
          margin-left: 8px;
          &.brand_name {
            font-size: 16px;
            margin-bottom: 4%;
          }
        }
      }
    }
    .nav_wrap {
      display: none;
      background: rgba(32,32,32,0.8);
      padding: 4px 5px 1px;
      height: 100vh;
      form {
        margin: 0;
        border: none;
        float: none;
        background: rgba(0,0,0,0.8);
        border-radius: 4px;
        margin-bottom: 4px;
        input {
          width: 90%;
          padding: 5px;
        }
      }
      .g_navi {
        display: block;
        float: none;
        margin-top: 4px;
        > a, .navbtn span {
          display: block;
          padding: 10px 20px;
          border-top: 1px solid #333;
          &:last-child {
            border-bottom: 1px solid #333;
          }
        }
        .navbtn span:after {
          font: normal normal normal 14px/1 FontAwesome;
          font-size: inherit;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          content: "\f107";
          float: right;
          margin-top: 0.2em;
        }
        .subnav {
          position: inherit;
          top: auto;
          left: auto;
          margin: 0;
          width: 100%;
          background: inherit;
          &:before {
            display: none;
          }
          li a {
            background: inherit;
            border-bottom: none;
            border-top: 1px solid #333;
            padding: 10px 0 10px 2em;
            text-align: left;
            &:before {
              font: normal normal normal 14px/1 FontAwesome;
              font-size: inherit;
              text-rendering: auto;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              content: "\f105";
              margin-right: 1em;
            }
          }
        }
      }
    }
  }
};

//====================================================
//  slick
//====================================================
.slick_wrap {
  overflow: hidden;
}
.slick-slider {
  .slick-list {
    position: relative;
    overflow: visible;
    display: block;
    margin: 0;
    padding: 0;
    .slick-track {
      width: 100%;
      overflow: hidden;
      .slick-slide {
      }
      .square {
        position: relative;
        @include sm {
          min-height: 400px;
        };
        img {
          width: 100%;
          @include sm {
            height: 400px;
          };
          @include xs {
            height: 50vw;
          };
        }
      }
    }
  }
  &.multiple-items {
    @include defaultWidth;
    &.square-slider {
      width: 400px;
      height: 400px;
      @include xs {
        width: 50vw;
        height: auto;
        img {
          width: 100%;
          height: 50vw;
        }
      };
    }
  }
}
.slick-prev, .slick-next {
  line-height: 0;
  font-size: 0;
  margin-top: -30px;
}
.slick-prev {
  left: -75px;
  &:before {
    content: " ";
    display: block;
    width: 60px;
    height: 65px;
    background: url(../img/svg/PREV.svg);
  }
}
.slick-next {
  right: -35px;
  &:before {
    content: " ";
    display: block;
    width: 60px;
    height: 65px;
    background: url(../img/svg/NEXT.svg);
  }
}
#music {
  .slick-track {
    background: black;
    @include xs {
      margin-left: 25vw;
      background: #eee;
    };
    a {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      padding-top: 80px;
      top: 0;
      left: 0;
      // background: black;
      background: rgba(0,0,0,0.8);
      z-index: 2000;
      opacity: 0;
      transition: all 0.1s linear;
      border: 20px solid rgba(255,255,255,0.0);
      @include sm {
        transform: scale(1.2);
      };
      @include xs {
        width: 50vw;
        height: auto;
        position: inherit;
        opacity: 1;
        top: auto;
        left: auto;
        margin: 0;
        padding: 0;
        background: white;
        color: #333;
        border: none;
        :after {
          font: normal normal normal 14px/1 FontAwesome;
          font-size: inherit;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          content: "\f105";
          position: absolute;
          color: #333;
          top: 50%;
          margin-top: -5px;
          right: 5px;
        }
        span {
          display: block;
          font-size: 10px;
          text-align: left;
          padding-left: 5px;
          border-left: 1px solid #eee;
          height: 65px !important;
          b,em,small {
            text-align: left;
          }
          em {
            width: 90%;
          }
          i:before {
            display: none;
          }
        }
      };
      span {
        display: block;
        text-align: center;
        b,em,small,i {
          display: block;
        }
        @include sm {
          b {
            font-size: 18px;
          }
          em {
            font-weight: bold;
            font-size: 22px;
            margin-top: 10px;
          }
          small {
            font-size: 14px;
            margin-top: 10px;
            opacity: 0.5;
          }
          i {
            font-size: 54px;
            margin-top: 80px;
            opacity: 0.5;
          }
        };
      }
      @include sm {
        &:hover {
          // transition: none;
          opacity: 1;
          border: 20px solid rgba(255,255,255,0.5);
          transform: scale(1);
        }
        i:hover {
          opacity: 1;
        }
      };
    }
  }
  .slick-prev, .slick-next {
  }
  .slick-prev {
    left: -275px;
  }
  .slick-next {
    right: -235px;
  }
}
@include xs {
  .slick-slide img {
    display: block;
    width: 100vw;
  }
  .slick-slider.multiple-items {
    width: 100vw;
  }
};
.for-img {
  position: relative;
  .slide_cont {
    position: absolute;
    bottom: 30px;
    left: 30px;
    z-index: 100;
    width: 1060px;
    background: rgba(0,0,0,0.5);
    overflow: hidden;
    .post_img {
      width: auto;
      height: 150px;
      border: 1px solid #666;
      overflow: hidden;
      float: left;
      img {
        height: 100%;
      }
    }
    .post_ttl {
      float: left;
      width: 800px;
      font-size: 18px;
      margin: 20px 0 15px 25px;
    }
    .post_content {
      margin: 0 0 0 25px;
      float: left;
      width: 500px;
      font-size: 14px;
    }
    .btn {
      float: right;
      margin-right: 25px;
      background: rgba(0,0,0,0.2);
      font-family: $subFontFamily;
      font-weight: bold;
      &:hover {
        background: rgba(255,255,255,0.6);
      }
    }
  }
}
@include xs {
  .for-img {
    position: static;
    width: 100vw;
    .slide_cont {
      position: static;
      bottom: auto;
      left: auto;
      z-index: 100;
      width: 100vw;
      background: white;
      color: #333;
      padding: 5px 2vw;
      .post_img {
        width: 26vw;
        height: auto;
        border: 1px solid #666;
        overflow: hidden;
        float: right;
        img {
          width: 100%;
        }
      }
      .post_ttl {
        width: 70vw;
        float: left;
        font-size: 14px;
        margin: 0;
        font-weight: bold;
      }
      .post_content {
        display: inline;
        float: none;
        font-size: 12px;
        margin: 0;
      }
      .btn {
        padding: 5px 10px;
        font-size: 14px;
        width: auto;
        margin-right: 0;
        background: rgba(0,0,0,0.2);
        font-family: $subFontFamily;
        font-weight: bold;
        border-width: 1px;
        border-radius: 4px;
        // color: #666;
        text-indent: 0;
        background: $accent;
        &:before {
          float: none;
          position: inherit;
          margin-right: 1em;
        }
      }
    }
  }
};

//====================================================
//  link_area
//====================================================
#link_area {
  background: #444;
  padding: 50px;
  @include xs {
    display: none;
  };
  .circle {
    padding: 0 30px;
    a {
      text-align: center;
      display: block;
      border: 1px solid #333;
      border-radius: 50%;
      padding: 25px 0 40px;
      img, span {
        display: inline-block;
      }
      img {
        width: 100px;
        opacity: 0.7;
        transition: all 0.15s ease-in-out;
      }
      .ttl {
        font-size: 23px;
        font-weight: bold;
        letter-spacing: 0.2em;
        font-family: $subFontFamily;
        color: #ccc;
        transition: all 0.15s ease-in-out;
      }
      .subttl {
        font-size: 14px;
        color: #999;
        transition: all 0.15s ease-in-out;
      }
      &:hover {
        box-shadow: 0 0 40px rgba(0,0,0,0.5) inset;
        img {
          opacity: 1;
          transition: all 0.15s ease-in-out;
        }
        .ttl {
          color: white;
          text-shadow: 2px 2px 4px #777,
                      -2px 2px 4px #777,
                      2px -2px 4px #777,
                      -2px -2px 4px #777;
          transition: all 0.15s ease-in-out;
        }
        .subttl {
          color: #ccc;
          transition: all 0.15s ease-in-out;
        }
      }
    }
  }
}

//====================================================
//  view
//====================================================
.view {
  padding-top: 70px;
  background: $bgColor;
  &.index_view {
    padding-top: 1px;
    @include sm {
      .headding_l {
        padding: 0 0 10px;
      }
    };
  }
  @include xs {
    padding-top: 50px;
    background: #eee;
    .headding_l {
      color: black;
    }
  };
  .view_slide {
    position: relative;
    box-shadow: 0 0px 10px rgba(0,0,0,0.3);
    background: #999;
    @include xs {
      background: white;
      box-shadow: none;
    };
  }
  .slide_cont {
    opacity: 0;
    transition: all 0.8s linear;
  }
  .slick-active {
    .slide_cont {
      opacity: 1;
      transition: all 0.8s linear;
    }
  }
  .view_post {
    margin: 40px auto 0;
    padding-bottom: 10px;
    position: relative;
    @include xs {
      margin: 0 auto;
      padding-bottom: 0;
      .row-20 {
        margin-left: -20px;
        margin-right: -15px;
      }
    };
    .post {
      position: relative;
      margin-bottom: 30px;
      @include xs {
        margin-bottom: 0;
        border-top: 1px solid #eee;
      };
      .post_inner {
        background: white;
        box-shadow: 0 0 10px rgba(0,0,0,0.3);
        @include xs {
          overflow: hidden;
          box-shadow: none;
          height: auto !important;
          &:after {
            font: normal normal normal 14px/1 FontAwesome;
            font-size: inherit;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            content: "\f105";
            position: absolute;
            color: #333;
            top: 50%;
            margin-top: -5px;
            right: 10px;
          }
        };
      }
      .post_date {
        position: absolute;
        background: url(../img/icon/bg-HS.png);
        width: 156px;
        height: 44px;
        background-size: 100% 100%;
        color: #666;
        font-size: 14px;
        top: 180px;
        left: -10px;
        text-align: center;
        line-height: 3.3;
        z-index: 2;
        @include xs {
          display: block;
          width: 180px;
          font-size: 10px;
          text-align: right;
          line-height: 1;
          position: inherit;
          background: none;
          float: right;
          height: auto;
          margin: 3px 20px 0 0;
        };
      }
      .post_img {
        width: 100%;
        height: 230px;
        overflow: hidden;
        background: #ccc;
        float: left;
        @include xs {
          width: 100px;
          height: 70px;
          margin: 5px 5px 5px 10px;
        };
        img {
          width: 100%;
          min-height: 100%;
          transition: all 0.15s ease-in-out;
          &:hover {
            transform: scale(1.2);
            transition: all 0.15s ease-in-out;
          }
        }
        iframe {
          width: 100%;
          height: 100%;
        }
      }
      .post_box {
        padding: 10px 20px;
        @include xs {
          padding: 5px 15px 5px 10px;
          position: relative;
          line-height: 1.0em;
        };
        a {
          color: #333;
          &:hover {
            color: $hoverColor;
          }
        }
        .post_ttl {
          font-size: 19px;
          font-weight: bold;
          @include xs {
            font-size: 13px;
            line-height: 1.4em;
            margin-bottom: 5px;
          };
        }
        .post_cont {
          font-size: 12px;
          @include xs {
            display: inline;
            font-size: 10px;
            padding-top: 0px;
            a {
              color: #666;
            }
          };
        }
      }
      &.rerease_post {
        @include sm {
          .post_inner {
            overflow: hidden;
          }
          .post_date {
            bottom: auto;
            top: 170px;
          }
          .post_img {
            width: 220px;
            height: 220px;
            img {
              width: 100%;
              min-height: 100%;
            }
          }
          .post_box {
            float: left;
            width: 330px;
            .post_ttl {
              font-size: 16px;
              font-weight: bold;
            }
            .edit_date {
              color: #999;
              margin: 5px 0;
            }
            .post_cont {
              font-size: 12px;
            }
          }
        };
      }
    }
  }
}

//====================================================
//  about
//====================================================
#about {
  background: url(../img/index/bg_about.jpg) no-repeat center top;
  background-size: cover;
  height: 100vh;
  @include xs {
    height: auto;
    background-size: 100% auto;
  };
  padding: 0;
  .bg_logo {
    background: url(../img/index/bg_logo.png) no-repeat center top;
    background-size: cover;
    height: 100vh;
    @include xs {
      height: auto;
    };
    padding: 0;
    .overlay {
      background: url(../img/common/overlay.png) repeat left top;
      border-top: 5px solid $accent;
      border-bottom: 2px solid black;
      height: 100vh;
      @include xs {
        border-top: 2px solid $accent;
        height: auto;
      };
      padding: 0;
    }
  }
  .headding_l {
    margin: 130px auto;
    @include xs {
      margin: 10px auto 10px;
    };
  }
  .about_logo {
    padding: 0 50px;
    color: black;
    font-family: $subFontFamily;
    font-weight: bold;
    letter-spacing: 1.0em;
    text-indent: -0.5em;
    line-height: 1.3;
    text-align: center;
    opacity: 0.9;
    @include xs {
      letter-spacing: 0.6em;
      text-indent: -0.1em;
      width: 30vw;
      padding: 0 5px;
      float: left;
    };
    .logo_box {
      background: rgba(255,255,255,0.4);
      padding: 10px;
      @include xs {
        padding: 5px;
      };
      .logo_box_inner {
        background: white;
        padding: 50px 90px;
        @include xs {
          padding: 10px;
          img {
            width: 100%;
          }
        };
      }
    }
    .logotype_s {
      font-size: 30px;
      margin: 20px 0 0;
      padding: 0;
      @include xs {
        display: none;
      };
    }
    .logotype_m {
      font-size: 36px;
      margin: 0;
      padding: 0;
      line-height: 0.7;
      @include xs {
        display: none;
      };
    }
  }
  .about_lead {
    padding: 70px 20px;
    font-size: 18px;
    line-height: 2;
    @include xs {
      padding: 0 10px;
      font-size: 10px;
      line-height: 1.2em;
    };
    em {
      color: $accentFontColor;
      font-weight: bold;
    }
  }
}

//====================================================
//  detailbox
//====================================================
.detailbox {
  @include xs {
    border-top: 1px solid #eee;
  };
  padding: 0;
  background: white;
  .headding_l {
    color: #333;
    padding: 40px 0 40px;
    margin: 0;
    @include xs {
      padding: 0;
      margin: 5px auto;
    };
  }
  .detail_tab {
    color: #333;
    padding: 40px 0 40px;
    margin: 0;
    overflow: hidden;
    @include xs {
      padding: 10px 0;
    };
    .btn {
      font-size: 24px;
      font-weight: bold;
      font-family: $subFontFamily;
      border-width: 2px;
      display: table-cell;
      float: left;
      margin: 0 0 0 2%;
      width: 32%;
      padding: 10px 0;
      @include xs {
        margin: 0 0 0 0.5%;
        width: 33%;
        font-size: 12px;
        padding: 5px;
      };
      &:first-child {
        margin: 0;
      }
      &.active,&:hover {
        opacity: 1;
        background: $accent;
        color: white;
        box-shadow: none;
        border-color: $accent;
      }
      &.btn_baloon {
        position: relative;
        &:after {
          content: '';
          position: absolute;
          left: 50%;
          bottom: -12px;
          margin-left: -10px;
          width: 0;
          height: 0;
          border-top: 10px solid $accent;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
        }
      }
    }
  }
  .interview_CONT {
    padding-top: 40px;
    @include xs {
      padding-top: 5px;
    };
  }
  .detail_side {
    padding-right:50px;
    @include xs {
      display: none;
    };
    &.music_side {
      padding-top: 60px;
    }
    .side_link {
      li {
        border-left: 11px solid black;
        padding: 3px 0 3px 10px;
        margin-bottom: 16px;
        a {
          font-size: 13px;
          font-weight: bold;
          color: black;
          &:hover {
            color: $hoverColor;
          }
        }
        &.sub {
          border: none;
          margin: 0;
          margin-bottom: 16px;
          h2 {
            border-left: 11px solid black;
            padding: 0 0 3px 10px !important;
            margin: 0 0 16px 0 !important;
            margin-bottom: 16px;
            span {
              font-size: 13px;
              font-weight: bold;
              color: black;
            }
          }
          ul {
            max-height: 200px;
            overflow-y: scroll;
          }
          li {
            border: none;
            // padding: 3px 0 3px 10px;
            // margin: 0;
            margin-bottom: 10px;
            a {
              font-size: 13px;
              font-weight: normal;
              color: #666;
              @include fontAwesome("\f0da",inherit,inherit);
              &:hover {
                color: $hoverColor;
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
  }
  .r_cont {
    color: #333;
    font-size: 14px;
    margin-bottom: 100px;
    @include xs {
      padding-left: 5px;
      padding-right: 5px;
      margin-bottom: 10px;
    };
    a {
      color: $accentFontColor;
      &:hover {
        opacity: 0.7;
      }
      &.link_post {
        display: block;
        text-align: right;
        border-top: 1px solid #ddd;
      }
    }
    .detail_bnr_area {
      margin: 30px auto 0;
      img {
        width: 100%;
      }
    }
    .detail_lead_area {
      @include sm {
        margin: 30px auto 0;
      };
    }
    .release_info {
      @include xs {
        margin: 0;
      };
      .rerease_post {
        @include xs {
          margin: 5px 0 0;
          padding: 0 0 5px;
          border-bottom: 1px solid #eee;
          position: relative;
          &:after {
            font: normal normal normal 14px/1 FontAwesome;
            font-size: inherit;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            content: "\f105";
            position: absolute;
            color: #333;
            top: 50%;
            margin-top: -5px;
            right: 5px;
          }
        };
        .post_inner {
          overflow: hidden;
          @include sm {
            background: #eee;
            box-shadow: 0 0 10px rgba(0,0,0,0.5);
            margin-bottom: 20px;
          };
          a:hover {opacity: 1;}
          .post_label {
            position: absolute;
            background: url(../img/icon/bg-HS.png);
            width: 156px;
            height: 44px;
            background-size: 100% 100%;
            color: #666;
            font-size: 14px;
            bottom: 25px;
            left: -10px;
            text-align: center;
            line-height: 3.3;
            z-index: 2;
            @include xs {
              display: none;
            };
          }
          .post_img {
            width: 150px;
            height: 150px;
            float: left;
            overflow: hidden;
            @include xs {
              width: 28vw;
              height: 28vw;
            };
            img {
              width: 100%;
              min-height: 100%;
              transition: all 0.15s ease-in-out;
              &:hover {
                transform: scale(1.2);
                transition: all 0.15s ease-in-out;
              }
            }
          }
          .post_box {
            float: left;
            width: 256px;
            padding: 5px 10px 0;
            line-height: 1.2em;
            @include xs {
              display: inline;
              width: 70%;
              &>div {
                // display: inline;
              }
            };
            .artist_name {
              font-size: 12px;
              margin: 0;
              font-weight: bold;
              a {color: #666;}
              a:hover {color: $accent;}
            }
            .product_name {
              margin: 0;
              font-size: 16px;
              font-weight: bold;
              a {color: #333;}
              a:hover {color: $accent;}
            }
            .release_date {
              font-size: 12px;
              margin: 0;
              a {color: #666;}
              a:hover {color: $accent;}
            }
            .post_cont {
              margin-top: 5px;
              font-size: 12px;
              a {color: #333;}
              a:hover {color: $accent;}
              @include xs {
                font-size: 10px;
                margin: 0;
              };
            }
            .btn {
              color: #666;
              font-weight: bold;
              border-width: 2px;
              width: 236px;
              margin-top: 10px;
              position: absolute;
              bottom: 30px;
              @include xs {
                display: none;
              };
              &:hover {
                color: white;
                border-color: $accent;
                background: $accent;
              }
              &:before {
                content: "\f0da";
                display: inline-block;
                font: normal normal normal 14px/1 FontAwesome;
                font-size: 15px;
                float: left;
              }
            }
          }
        }
      }
    }
    .movie_info {
      @include xs {
        margin: 0;
        border-top: 1px solid #eee;
      };
      .movie_post {
        @include xs {
          border-bottom: 1px solid #eee;
          padding: 5px 0;
          &:after {
            font: normal normal normal 14px/1 FontAwesome;
            font-size: inherit;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            content: "\f105";
            position: absolute;
            color: #333;
            top: 50%;
            margin-top: -5px;
            right: 0px;
          }
        };
        .post_inner {
          overflow: hidden;
          @include sm {
            background: #eee;
            box-shadow: 0 0 10px rgba(0,0,0,0.5);
            margin-bottom: 20px;
          };
          @include xs {
            height: auto !important;
          };
          a:hover {opacity: 1;}
          .post_img {
            width: 100%;
            height: 230px;
            overflow: hidden;
            img {
              width: 100%;
              min-height: 100%;
              transition: all 0.15s ease-in-out;
              &:hover {
                transform: scale(1.2);
                transition: all 0.15s ease-in-out;
              }
            }
            iframe {
              width: 100%;
              height: 100%;
            }
            @include xs {
              width: 30vw;
              height: 20vw;
              margin-right: 5px;
              float: left;
            };
          }
          .post_box {
            width: 100%;
            padding: 5px 10px;
            line-height: 1.5em;
            @include xs {
              padding: 0 10px 0 0;
              line-height: 1.2em;
            };
            .artist_name {
              font-size: 12px;
              margin: 0;
              font-weight: bold;
              color: #666;
              &:hover {color: $accent;}
              @include xs {
                font-size: 10px;
              };
            }
            .post_date {
              float: right;
              font-size: 12px;
              margin: 0;
              color: #666;
              &:hover {color: $accent;}
              @include xs {
                font-size: 10px;
              };
            }
            .post_ttl {
              margin: 0;
              font-size: 20px;
              font-weight: bold;
              a {color: #333;}
              a:hover {color: $accent;}
              @include xs {
                font-size: 14px;
              };
            }
            .post_cont {
              margin: 5px 0;
              font-size: 14px;
              a {color: #666;}
              a:hover {color: $accent;}
              @include xs {
                font-size: 11px;
              };
            }
          }
        }
      }
    }
  }
  &#interview {
    padding-top: 80px;
    @include xs {
      padding-top: 50px;
    };
  }
}

//====================================================
//  footer
//====================================================
footer {
  background: #1c1c1c;
  padding-top: 50px;
  @include xs {
    border-top: 1px solid $accent;
    padding-top: 0;
  };
  .admin {
    position: fixed;
    top: 10px;
    right: 20px;
    line-height: 0;
    background: black;
    padding: 14px 15px;
    font-size: 30px;
    border-radius: 50%;
    transition: all 0.5s ease-in-out;
    z-index: 1031;
    &:hover {
      transform: rotate(360deg);
      transition: all 0.5s ease-in-out;
    }
    @include xs (){
      display: none;
    };
  }
  .pagetop {
    position: fixed;
    width: 50px;
    height: 50px;
    bottom: 20px;
    right: 20px;
    color: $accentFontColor;
    z-index: 1;
    i {
      font-size: 60px;
    }
    &:hover {
      color: #f09;
      bottom: 30px;
    }
  }
  h4 {
    @include sm {
      span {
        color: $accentFontColor;
        font-weight: bold;
      }
    };
    font-size: 18px;
    margin-bottom: 40px;
    @include xs {
      &:first-letter {
        font-weight: bold;
        color: $accent;
      }
      font-family: $subFontFamily;
      font-size: 16px;
      margin-bottom: 10px;
      cursor: pointer;
      position: relative;
      padding: 5px 0;
      &:after {
        content: "\f078";
        position: absolute;
        right: 0;
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: 10px;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin-top: 3px;
      }
    };
  }
  .toggle {
    @include xs {
      display: none;
      padding-bottom: 10px;
    };
  }
  .f_featured {
    @include xs {
      border-bottom: 1px solid #444;
    };
    .featured_post {
      overflow: hidden;
      margin-bottom: 10px;
    }
    .post_img {
      width: 70px;
      height: 50px;
      overflow: hidden;
      float: left;
      img {
        width: 100%;
        min-height: 100%;
      }
    }
    .post_cont {
      width: 250px;
      @include xs {
        width: 68%;
      };
      float: left;
      margin-left: 20px;
      line-height: 1.4;
      .date {
        display: block;
        font-size: 10px;
        color: #666;
      }
    }
    .btn {
      margin-top: 10px;
      color: #666;
      font-weight: bold;
      &:hover {
        color: white;
      }
    }
  }
  .f_about {
    @include xs {
      border-bottom: 1px solid #444;
    };
    p {
      font-size: 12px;
      color: #666;
    }
    .social {
      text-align: center;
      li {
        display: inline-block;
        margin: 0;
        a {
          display: block;
          width: 50px;
          height: 50px;
          border: 1px solid;
          text-align: center;
          line-height: 2.2;
          font-size: 23px;
          color: #666;
          &:hover {
            color: white;
          }
        }
      }
    }
  }
  .f_contact {
    overflow: hidden;
    @include sm {
      padding-left: 20px !important;
    };
    @include xs {
      border-bottom: 1px solid #444;
    };
    input, textarea {
      color: #666;
      width: 100%;
      background: transparent;
      padding: 10px;
      border: 1px solid #666;
      margin-bottom: 10px;
      transition: all 0.15s ease-in-out;
      &[type=submit] {
        width: 200px;
        float: right;
        height: 50px;
        font-weight: bold;
        @include xs {
          width: 100%;
          height: 40px;
        };
      }
      &:hover {
        color: white;
        border-color: white;
        transition: all 0.15s ease-in-out;
      }
    }
    textarea {
      height: 75px !important;
      margin-bottom: 5px;
    }
  }
  .sponsor {
    margin: 80px 0;
    @include xs {
      margin: 10px 0;
    };
    dt {
      clear: both;
      width: 110px;
      height: 24px;
      line-height: 2;
      letter-spacing: 1em;
      text-align: center;
      background: url(../img/icon/bg_sponsor.png) no-repeat center top;
      background-size: 100% 100%;
      float: left;
      @include xs {
        float: none;
        width: 100%;
        background: black;
      };
    }
    dd {
      width: $baseMinWidth - 110px - 30px;
      margin-top: 0.1em;
      margin-bottom: 30px;
      float: left;
      margin-left: 30px;
      font-size: 14px;
      color: #666;
      line-height: 1.6;
      @include xs {
        font-size: 10px;
        float: none;
        width: 100%;
        margin: 5px 0;
      };
      a {
        color: inherit;
        text-decoration: underline;
        &:hover {
          color: $accent;
        }
      }
    }
  }
  //
  // f_nab_wrap
  //
  .f_nab_wrap {
    padding: 50px 0;
    background: black;
    font-family: $subFontFamily;
    border-bottom: 2px solid $accent;
    @include xs {
      border-top: 1px solid $accent;
      border-bottom: 1px solid $accent;
      padding: 10px 0;
    };
    .f_logo {
      width: 200px;
      font-weight: bold;
      letter-spacing: 0.8em;
      letter-spacing: 0.4em\9;
      text-align: center;
      padding: 10px 0;
      @include xs {
        margin: auto;
        width: 100%;
        text-align: center;
        float: none;
        img {
          width: 50px !important;
          margin: 0 auto;
        }
      };
      img {
        width: 100%;
      }
      img, span {
        display: block;
      }
      .logotype_s {
        margin-top: 20px;
        margin-bottom: 0;
        line-height: 1;
        font-size: 20px;
        @include xs {
          margin-top: 10px;
          font-size: 10px;
        };
      }
      .logotype_m {
        line-height: 1;
        font-size: 24px;
        @include xs {
          font-size: 16px;
        };
      }
    }
    .f_nav {
      .left {
        margin-right: 60px;
        font-size: 18px;
        font-weight: bold;
        @include xs {
          display: none;
          width: 100%;
          li a {
            font-size: 14px;
            padding: 8px 10px;
            width: 100%;
            display: block;
            border-bottom: 1px solid #333;
            &:after {
              font: normal normal normal 14px/1 FontAwesome;
              font-size: inherit;
              text-rendering: auto;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              content: "\f105";
              float: right;
              margin-top: 0.2em;
            }
          }
        };
        .sub {
          font-weight: normal;
          color: #666;
          &:hover {
            color: $accentFontColor;
          }
          @include xs {
            font-size: 14px;
            padding: 8px 1em;
            &:after {
              display: none;
            }
            &:before {
              font: normal normal normal 14px/1 FontAwesome;
              font-size: inherit;
              text-rendering: auto;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              content: "\f105";
              margin-right: 1em;
            }
          };
        }
      }
      address {
        clear: both;
        font-size: 12px;
        text-align: right;
        padding-top: 50px;
        @include xs {
          padding: 10px 0 0;
          text-align: center;
          font-size: 10px;
        }
      }
    }
  }
  // contact_form
  .contact_form {
    .wpcf17-form-control-wrap {
      position: relative;
    }
    span.wpcf7-not-valid-tip {
        color: #F09;
        font-size: 1em;
        display: block;
        position: absolute;
        bottom: -10px;
        right: 0;
        font-size: 10px;
        font-weight: bold;
    }
    br {
      display: none;
    }
    .message span.wpcf7-not-valid-tip {
      top: -10px;
    }
    div.wpcf7-response-output {
      margin-top: 0px;
      border: 1px solid #f09;
      width: 230px;
      position: absolute;
      bottom: 0;
      font-size: 12px;
    }
    div.wpcf7-mail-sent-ok {
      border: 1px solid $accent;
    }
    div.wpcf7-mail-sent-ng {
      border: 1px solid red;
    }
    div.wpcf7-spam-blocked {
      border: 1px solid #ffa500;
    }
    div.wpcf7-validation-errors {
      border: 1px solid #f09;
    }
  }
}

//====================================================
//  single
//====================================================
.interview_cont {
  @include sm {
    img.aligncenter {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    img.alignright {
      padding: 4px;
      margin: 0 0 2px 7px;
      display: inline;
    }

    img.alignleft {
      padding: 4px;
      margin: 0 7px 2px 0;
      display: inline;
    }
    .alignright {
      float: right;
    }
    .alignleft {
      float: left
    }
    iframe {
      width: 100%;
      height: 500px;
    }
  };
  @include xs {
    font-size: 12px;
    iframe {
      width: 100%;
      height: 70vw;
    }
  };
  .aligncenter {
    margin-left: auto;
    margin-right: auto;
    @include xs {
      max-width: 100% !important;
    };
    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .wp-caption-text {
    padding: 0;
    text-align: right;
    font-size: 10px;
  }
  .movie_frame {
    width: 100%;
    margin: 10px 0;
    iframe {
      width: 100%;
    }
  }
  h2 {
    @include xs {
      font-size: 18px;
    };
  }
  h3 {
    color: #666;
    padding-bottom: 10px;
    border-bottom: 1px solid #999;
    margin-bottom: 20px;
    @include xs {
      font-size: 16px;
    };
    &:before {
      content: "";
      display: inline-block;
      width: 10px;
      height: 20px;
      background: #666;
      margin-right: 10px;
    }
  }
  h4 {
    color: #999;
    margin: 20px 0;
    @include xs {
      font-size: 14px;
    };
  }
  form {
    color: #666;
    padding-bottom: 10px;
    border-bottom: 1px solid #999;
    margin: 20px 0;
    overflow: hidden;
  }
  .feature {
    overflow: hidden;
    border-bottom: 1px solid #999;
    padding: 0 0 20px;
    margin: 0 0 20px;
    .post_img {
      float: left;
      width: 10%;
      img {
        width: 100%;
        height: auto;
      }
    }
    .post_box {
      float: left;
      width: 90%;
      padding: 0 0 0 20px;
    }
    .post_cont {
      clear: both;
      padding: 5px 10px;
      border: 1px solid #eee;
      background: #f9f9f9;
      p {
        margin: 0;
        padding: 0;
        font-size: 12px;;
      }
    }
  }
  img {
    max-width: 100%;
    height: auto;
    @include xs {
      display: block;
      margin: auto;
    };
  }
  .nav-below {
    border-top: 1px solid #666;
    border-bottom: 1px solid #666;
    padding: 30px 0;
    margin-bottom: 100px;
    overflow: hidden;
    .nav-previous {
      float: left;
    }
    .nav-next {
      float: right;
    }
    @include xs {
      padding: 10px 0;
      margin-bottom: 10px;
      // .nav-previous {
      //   border-right: 1px solid #666;
      //   padding-right: 10px;
      // }
      // .nav-next {
      //   border-left: 1px solid #666;
      //   padding-left: 10px;
      // }
    };
  }
  hr {
    border-color: #999;
  }
  .btn {
    color: #666 !important;
    border-width: 2px;
    font-weight: bold;
    &:hover {
      color: white !important;
      border-color: #666;
      background: #666;
    }
  }
  &.asian_cont {
    h3 {
      border: none;
      font-size: 20px;
    }
  }
}

.sns-icons {
  width: 94%;
  margin: 20px 3%;
  padding: 0;
  overflow: hidden;
  &>a {
    display: block;
    float: left;
    vertical-align: middle;
    width: 31.33%;
    margin: 0 1%;
    padding: 9px 0 7px;
    font-size: 14px;
    text-align: center;
    color: white !important;
    line-height: 2;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    text-decoration: none;
    position: relative;
    .sns_count {
      position: absolute;
      font-size: 12px;
      right: 2px;
      bottom: -3px;
    }
  }
  &>a:hover {
    -webkit-transition: 0.15s opacity linear;
    -moz-transition: 0.15s opacity linear;
    -ms-transition: 0.15s opacity linear;
    -o-transition: 0.15s opacity linear;
    transition: 0.15s opacity linear;
    opacity: 0.7;
    filter: alpha(opacity=70);
  }
}
blockquote {
  color: #444;
  border: 1px solid #ccc;
  margin: 1em 0 2em;
  font-size: 1em;
  padding: 1em 2.5em;
  border-radius: 10px;
  position: relative;
  background: #eeeeee;
  @include xs {
    padding: 0.5em;
  };
  &:before {
    font-family: 'FontAwesome';
    content: '\f10d';
    position: absolute;
    width: 1em;
    height: 1em;
    padding-top: 0.25em;
    border-radius: 50%;
    display: block;
    text-align: center;
    left: -0.4em;
    top: -0.5em;
    font-size: 20px;
    line-height: 1;
    color: #999;
    @include xs {
      font-size: 14px;
    };
  }
  &:after {
    font-family: 'FontAwesome';
    content: '\f10e';
    position: absolute;
    width: 1em;
    height: 1em;
    padding-top: 0.25em;
    border-radius: 50%;
    display: block;
    text-align: center;
    right: -0.4em;
    bottom: -0.2em;
    font-size: 20px;
    line-height: 1;
    color: #999;
    @include xs {
      font-size: 14px;
    };
  }
}
