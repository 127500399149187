//====================================================
//  _typography.scss
//====================================================

$baseFontFamily: 'Noto Sans Japanese', Meiryo, メイリオ, 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', Montserrat, Roboto, sans-serif;
$subFontFamily: 'Montserrat','Roboto', sans-serif;
$baseFontSize: 75%;
$baseLineHeight: 1.6;

@mixin fs($size, $base: 10) {
  font-size: $size + px;
  font-size: ($size / $base) + rem;
}


//====================================================
//  _sizing.scss
//====================================================

$baseMinWidth: 1120px;
$maincontWidth: 810px;

//====================================================
//  _sidebar.scss
//====================================================

$sidebarWidth: 230px;

//====================================================
//  _colors.scss
//====================================================
$base:      #ffffff;
$sub:       #333333;
$accent:    #67bae8;

$white:     #fff;
$silver:    #F4F4F4;
$lightgrey: #ececec;
$gray:      #ccc;
$midgrey:   #777;
$darkgrey:  #444;
$black:     #222;
$trueblack: #000;

$bgColor: #333333;

$baseFontColor: $base;
$subFontColor: #333333;
$accentFontColor: $accent;

$spotlightMainColor: #ffcc33;



//====================================================
//  _syntax.scss
//====================================================

// Cools
$paleblue:   #BDDCDC;
$blue:       #92BFBF;
$slate:      #5F777E;
$palegreen:  #D0EDA7;
$green:      #B4D388;
$darkgreen:  #809161;

// Warms
$paleyellow: #F5F5AE;
$yellow:     #EDE480;
$orange:     #F49D62;
$red:        #CF5340;
$darkred:    #893121;
$maroon:     #55201B;

//====================================================
//  _linkstyle.scss
//====================================================

$linkColor: $baseFontColor;
$linkFontWeight: bold;
$hoverColor: $accentFontColor;
$linkDecoration: none;
$hoverDecoration: none;

//====================================================
//  _whitespace.scss
//====================================================

$baseMargin: 20px;
$basePadding: 20px;
$tablePadding: 10px;

//====================================================
//  _borders.scss
//====================================================

$baseBorderStyle: solid;
$borderColor: $gray;
$baseBorderRadius: 5px;
$baseBorderWidth: 1px;

$baseBorder: $baseBorderStyle $borderColor $baseBorderWidth;

//====================================================
//  _breakpoints.scss
//====================================================


//iPhoneのポートレート（縦向き）	320px	デフォルトのCSS
//iPhoneのランドスケープ（横向き）	480px
//iPadのポートレート	768px
$smallBreak: 767px;
$mediumBreak: 768px;
//デスクトップPC	1024px


@mixin xs() {
  @media screen and (max-width: $smallBreak) {
    @content;
  }
}

@mixin sm() {
  @media screen and (min-width: $mediumBreak) {
    @content;
  }
}
