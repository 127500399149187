//====================================================
//  IE Hack
//====================================================

@mixin opacity($transparent) {
  opacity:$transparent;
  filter: alpha(opacity= $transparent * 100);
}

@mixin clearfix {
  overflow: hidden;
  *zoom: 1;
}

//====================================================
//  Mixin
//====================================================

@mixin defaultWidth {
  width: $baseMinWidth;
  margin: 0 auto;
}

@mixin noHover {
  &:hover {
    img {
      @include opacity(1);
    }
  }
}

@mixin textIn {
  text-indent: -9999px;
  display: block;
}

@mixin hoverAction($opacity:0.7,$time:0.15s) {
  &:hover {
    transition: $time opacity linear;
    @include opacity($opacity);
  }
}

@mixin hrList($marginTop,$marginRight,$marginBottom,$marginLeft) {
  margin: 0 0 0 (-$marginLeft);
  @include clearfix;
  &>li {
    float: left;
    margin: $marginTop $marginRight $marginBottom $marginLeft;
  }
}

@mixin bgs($width,$height) {
  width: $width;
  height: $height;
  background-size: $width $height;
}

@mixin glidList($column,$margin) {
  @include clearfix;
  &>li {
    widows: $baseMinWidth / $column - $margin;
    float: left;
    margin: 0 0 $margin $margin;
  }
}

@mixin borderLink ($linkColor,$borderHeight,$left,$bottom,$time) {
  position: relative;
  &:after {
    content: "";
    height: $borderHeight;
    position: absolute;
    background: $linkColor;
    left: $left;
    bottom: $bottom;
    width: 0;
    transition: width $time ease-in-out;
  }
  &:hover:after {
    width: 100%;
  }
}

@mixin ghostbutton($font-size, $font-color, $border-size, $border-color, $padding, $transition-speed, $hover-color) {
  display:inline-block;
  text-decoration:none;
  text-transform:uppercase;
  font-size: $font-size;
  color:$font-color;
  border:$border-size solid $border-color;
  padding:$padding;
  transition: color $transition-speed, background $transition-speed;
  &:hover {
    background:$border-color;
    color:$hover-color;
  }
}

@mixin fontAwesome($cont,$contColor,$contSize) {
  &::before {
    content: $cont;
    font: normal normal normal 14px/1 FontAwesome;
    color: $contColor;
    font-size: $contSize;
    margin-right: 0.2em;
  }
}
